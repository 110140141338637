'use client';

import { FC, ReactNode, RefObject, createContext, useRef } from 'react';

export interface IHeaderContext {
	headerRef: RefObject<HTMLDivElement> | null;
}

export const HeaderContext = createContext<IHeaderContext>({ headerRef: null });

export const HeaderProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const headerRef = useRef<HTMLDivElement>(null);

	return <HeaderContext.Provider value={{ headerRef }}>{children}</HeaderContext.Provider>;
};
