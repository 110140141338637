'use client';

import { Button } from '@/components/core/Button/Button';
import { SearchIcon } from '@/icons/SearchIcon';
import { css } from '@/styled-system/css';
import { useOutsideClick } from '@/utils/handleOutsideClick';
import { LazyMotion, domAnimation, m as motion } from 'framer-motion';
import { FC, useRef, useState } from 'react';
import { Flex, FlexProps, styled } from 'styled-system/jsx';

interface ISearchInputProps extends FlexProps {
	placeholder: string;
	searchUrl: string;
}

function generateSearchPath(searchUrl: string, searchValue?: string) {
	if (searchValue) {
		return searchUrl + '?s=' + encodeURIComponent(searchValue);
	} else {
		return null;
	}
}

export const SearchInput: FC<ISearchInputProps> = ({ placeholder, searchUrl, ...rest }) => {
	const [isExpanded, setExpanded] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);
	const wrapperRef = useRef<HTMLInputElement>(null);

	const searchFunction = () => {
		const searchPath = generateSearchPath(searchUrl, inputRef?.current?.value);

		if (searchPath) {
			//Router push doesn't work fully: With client side navigation, the browser navigates, but a Google search snippet on the page does not execute.
			window.location.href = searchPath;
		}
	};

	const handleSearchButtonClick = () => {
		if (isExpanded) {
			searchFunction();
		} else {
			setExpanded(true);
		}
	};

	useOutsideClick(wrapperRef, () => setExpanded(false));

	return (
		<LazyMotion features={domAnimation} strict>
			<Flex
				ref={wrapperRef}
				position="relative"
				alignItems="center"
				borderRadius={48}
				borderWidth={isExpanded ? '1px' : undefined}
				borderLeftRadius={isExpanded ? 0 : undefined}
				borderColor={isExpanded ? 'icons.regular.default' : 'transparent'}
				pr={isExpanded ? 4 : 0}
				_print={{ display: 'none' }}
				{...rest}
			>
				<Button
					title={placeholder}
					aria-label={placeholder}
					variant="unstyled"
					size="unsized"
					onClick={handleSearchButtonClick}
					leftIcon={
						<SearchIcon width={isExpanded ? 24 : 32} height={isExpanded ? 24 : 32} color="icons.regular.default" />
					}
					padding={!isExpanded ? 0 : undefined}
					paddingY={isExpanded ? '3px' : undefined}
					paddingRight={isExpanded ? '1px' : undefined}
					paddingLeft={isExpanded ? '6px' : undefined}
					border={0}
				></Button>
				<motion.div
					initial={{ width: 0 }}
					animate={{ width: isExpanded ? '140px' : 0 }}
					transition={{ duration: 0.3 }}
					onAnimationComplete={() => {
						inputRef?.current?.focus();
					}}
				>
					<styled.input
						ref={inputRef}
						type="text"
						className={css({ padding: '0 8px 0 4px', width: '100%' })}
						fontSize={16}
						placeholder={placeholder}
						_focusVisible={{ outline: '0' }}
						style={{ display: isExpanded ? 'block' : 'none' }}
						onKeyUp={(event) => {
							if (event.key === 'Enter') {
								searchFunction();
							}
						}}
					/>
				</motion.div>
			</Flex>
		</LazyMotion>
	);
};
