'use client';

import { AppBannerRecipeVariants, appBannerRecipe } from '@/components/shared/AppBanner/AppBanner.recipe';
import { CloseIcon } from '@/icons/CloseIcon';
import { styled } from '@/styled-system/jsx';
import { throttle } from 'lodash';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';

interface IAppBannerProps {
	id: string;
	title: string;
	description?: string;
	color?: AppBannerRecipeVariants['color'];
	actionLink?: ReactNode;
	image?: {
		url: string;
		alt: string;
	};
}

export const AppBanner: FC<IAppBannerProps> = ({ id, color, title, description, actionLink, image }) => {
	const [isBannerVisible, setIsBannerVisible] = useState(true);
	const classes = appBannerRecipe({ color });
	const bannerRef = useRef<HTMLDivElement>(null);
	const imageRef = useRef<HTMLImageElement>(null);
	const linkWrapperRef = useRef<HTMLDivElement>(null);
	const descriptionRef = useRef<HTMLParagraphElement>(null);
	const lastScrollYRef = useRef<{ lastScrollY: number }>({ lastScrollY: 0 });

	useEffect(() => {
		const handleResize = throttle(() => {
			const currentScrollY = window.scrollY;
			const lastScrollY = lastScrollYRef.current.lastScrollY;
			const isScrollingDown = currentScrollY > lastScrollY;
			const scrollDifference = Math.abs(currentScrollY - lastScrollY);

			if (scrollDifference > 80) {
				if (bannerRef.current && imageRef.current && linkWrapperRef.current) {
					bannerRef.current.style.height = isScrollingDown ? '48px' : '80px';
					imageRef.current.style.transform = isScrollingDown ? 'scale(0.5)' : 'scale(1)';
					linkWrapperRef.current.style.transform = isScrollingDown ? 'scale(0.8)' : 'scale(1)';
				}

				if (descriptionRef.current) {
					descriptionRef.current.style.maxHeight = isScrollingDown ? '18px' : '34px';
					descriptionRef.current.style.webkitLineClamp = isScrollingDown ? '1' : '2';
				}

				lastScrollYRef.current.lastScrollY = Math.max(0, currentScrollY);
			}
		}, 200);

		window.addEventListener('scroll', handleResize);

		return () => {
			window.removeEventListener('scroll', handleResize);
		};
	}, []);

	if (!isBannerVisible) {
		return null;
	}

	return (
		<styled.div id={id} ref={bannerRef} className={classes.container} role="banner">
			<styled.div className={classes.contentContainer}>
				<button
					className={classes.closeButton}
					onClick={() => setIsBannerVisible(false)}
					role="button"
					aria-label="Close banner"
				>
					<CloseIcon />
				</button>

				{image?.url && <styled.img ref={imageRef} className={classes.image} src={image.url} alt={image?.alt} />}

				<styled.div w="full">
					<styled.h6 className={classes.title}>{title}</styled.h6>
					{description && (
						<styled.p ref={descriptionRef} className={classes.description}>
							{description}
						</styled.p>
					)}
				</styled.div>
			</styled.div>

			<styled.div ref={linkWrapperRef} className={classes.actionLinkWrapper}>
				{actionLink}
			</styled.div>
		</styled.div>
	);
};
