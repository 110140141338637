'use client';

import { PageContext } from '@/utils/pageContextProvider';
import { type SVGAttributes, useContext } from 'react';

export const DownIcon = (props: SVGAttributes<SVGElement>) => {
	const { siteName } = useContext(PageContext);

	if (siteName === 'vosti') {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M26.1742 5.72974V25.2289C26.1742 25.505 25.9503 25.7289 25.6742 25.7289H6.17505V24.7289H24.4671L6.22361 6.48541L6.93072 5.7783L25.1742 24.0217V5.72974H26.1742Z"
					fill="currentColor"
				/>
			</svg>
		);
	}

	return (
		<svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M26.5 13.48L25 11.98L16 21.19L7 11.98L5.5 13.48L16 24.02L26.5 13.48Z" fill="currentColor"></path>
		</svg>
	);
};
