import { IFormContainerBlockContent } from '@/components/block-templates/Forms/FormContainerBlockTemplate/FormContainerBlockTemplate';
import { IFormSalesforceBlockContent } from '@/components/shared/Forms/FormSalesforce';
import { IRegisteredSelectionItem } from '@/components/shared/Forms/FormTrackingProvider';
import { ITrackedProps } from '@/components/shared/tracking/Tracked';
import {
	FormFieldDisplayMode,
	IFormFieldContent,
	IFormFieldResponse,
	ISelectFormFieldContent,
	ISelectionItemContent,
	SelectFormFieldDisplayMode,
} from '@/interfaces/blocks/forms';
import { EventAttachMode, ITrackingInformation } from '@/types/tracking';
import { CustomTrackingEvent, doTrack, getTrackingPropertiesFromCMSBlock } from '@/utils/tracking/tracking';
import { RefObject } from 'react';

const CONVERSION_SUBMIT_SUCCESS = 'vt-form-submit-success';

const TRACKING_KEYS_TO_OMIT: Array<keyof ITrackingInformation> = ['category', 'conversionId'];

export enum FormTrackingEventTrigger {
	ValidationError = 'validation-error',
	Submit = 'submit',
	SubmitSuccess = 'submit-success',
	TechnicalError = 'technical-error',
}

export function getFormWrapperTrackingProps(
	formContainer: IFormContainerBlockContent,
	salesforceForm?: IFormSalesforceBlockContent
): Partial<ITrackedProps> {
	const trackingProperties = getFormWrapperTrackingInformationFromCMS(formContainer, salesforceForm);

	const trackedBlockInformation = trackingProperties?.trackedBlockInformation;
	const cmsTrackingInformation = trackingProperties?.cmsTrackingInformation;

	const customTrackingInformation = getCustomEventFormTrackingInformation({
		category: cmsTrackingInformation?.category,
	});

	// Some tracking information key from the block should be removed from the cmsTrackingInformation
	// This is because cmsTrackingInformation is prioritized over custom event tracking information (trackingInformation prop in tracked component)
	for (const key of TRACKING_KEYS_TO_OMIT) {
		if (cmsTrackingInformation?.[key]) {
			cmsTrackingInformation[key] = undefined;
		}
	}

	if (trackedBlockInformation) {
		trackedBlockInformation.blockIsRelevant = true;
	}

	return {
		trackedBlockInformation,
		cmsTrackingInformation,
		trackingInformation: customTrackingInformation,
		attachTrackingEvent: EventAttachMode.Custom,
	};
}

export function getFormTrackingProps(salesforceForm?: IFormSalesforceBlockContent): Partial<ITrackedProps> {
	const trackingProperties = getTrackingPropertiesFromCMSBlock(salesforceForm);

	const trackedBlockInformation = trackingProperties?.trackedBlockInformation;
	const cmsTrackingInformation = trackingProperties?.cmsTrackingInformation;

	const customTrackingInformation = getCustomEventFormTrackingInformation({
		category: cmsTrackingInformation?.category,
	});

	// Some tracking information key from the block should be removed from the cmsTrackingInformation
	// This is because cmsTrackingInformation is prioritized over custom event tracking information (trackingInformation prop in tracked component)
	for (const key of TRACKING_KEYS_TO_OMIT) {
		if (cmsTrackingInformation?.[key]) {
			delete cmsTrackingInformation[key];
		}
	}

	if (trackedBlockInformation) {
		trackedBlockInformation.blockIsRelevant = false;
	}

	return {
		trackedBlockInformation,
		cmsTrackingInformation,
		trackingInformation: customTrackingInformation,
		attachTrackingEvent: EventAttachMode.Custom,
	};
}

export function getCustomEventFormTrackingInformation(relevantTrackingInformation: Partial<ITrackingInformation>): {
	[eventName in FormTrackingEventTrigger]: ITrackingInformation;
} {
	const other: ITrackingInformation = {
		techCategory: 'form',
		nonInteraction: false,
		category: 'form',
	};

	return {
		[FormTrackingEventTrigger.Submit]: {
			action: FormTrackingEventTrigger.Submit,
			...other,
			...(relevantTrackingInformation.category && { conversionId: 'vt-form-' + relevantTrackingInformation.category }),
			category: relevantTrackingInformation.category ?? 'form',
		},
		[FormTrackingEventTrigger.SubmitSuccess]: {
			action: FormTrackingEventTrigger.SubmitSuccess,
			...other,
			conversionId: CONVERSION_SUBMIT_SUCCESS,
		},
		[FormTrackingEventTrigger.ValidationError]: {
			action: FormTrackingEventTrigger.ValidationError,
			...other,
		},
		[FormTrackingEventTrigger.TechnicalError]: {
			action: FormTrackingEventTrigger.TechnicalError,
			...other,
		},
	};
}

export function getFormWrapperTrackingInformationFromCMS(
	formContainer: IFormContainerBlockContent,
	salesforceForm?: IFormSalesforceBlockContent
) {
	if (!salesforceForm) {
		return {};
	}

	const trackingProperties = getTrackingPropertiesFromCMSBlock(salesforceForm);

	if (trackingProperties.trackedBlockInformation) {
		trackingProperties.trackedBlockInformation.blockId = formContainer.contentType.at(-1) ?? '';
		trackingProperties.trackedBlockInformation.blockName = formContainer.contentType.at(-1) ?? '';
		trackingProperties.trackedBlockInformation.blockInstanceId = formContainer?.contentLink?.id?.toString();
		trackingProperties.trackedBlockInformation.blockInstanceName = formContainer?.name;
	}

	return trackingProperties;
}

export function sendFormTrackingEvent(
	element: HTMLElement | undefined | null,
	eventTrigger: FormTrackingEventTrigger,
	conversionId?: string
) {
	if (element) {
		doTrack(CustomTrackingEvent.FromTarget(element, eventTrigger), conversionId ? { conversionId } : undefined);
	}
}

export function formTracking({
	salesforceForm,
	formFieldsAbove,
	formFieldsBelow,
	eventTrigger,
	formValues,
}: {
	salesforceForm: IFormSalesforceBlockContent | undefined | null;
	formFieldsAbove?: IFormFieldResponse[] | undefined;
	formFieldsBelow?: IFormFieldResponse[] | undefined;
	eventTrigger: FormTrackingEventTrigger;
	formValues?: Record<string, string>;
}) {
	if (!salesforceForm) {
		throw Error();
	}

	const _this = {
		sendFormTrackingEvent: (element: HTMLElement | undefined | null) => {
			let conversionId: string | undefined = undefined;

			if (eventTrigger === FormTrackingEventTrigger.Submit) {
				conversionId = salesforceForm.conversionId;
			}

			if (element) {
				doTrack(CustomTrackingEvent.FromTarget(element, eventTrigger), conversionId ? { conversionId } : undefined);
			}

			return _this;
		},
		sendSelectionItemEvents: (registeredItems: IRegisteredSelectionItem[]) => {
			if (!registeredItems.length) {
				return;
			}

			const formFields = getSelectFormFields(salesforceForm, formFieldsAbove, formFieldsBelow);
			const excludeNonSelected =
				eventTrigger === FormTrackingEventTrigger.Submit || eventTrigger === FormTrackingEventTrigger.SubmitSuccess;

			for (const formField of formFields) {
				const selectionItems = getRelevantSelectionItems(formField).filter(
					(item) => !excludeNonSelected || isItemAccepted(item, formValues)
				);
				const registeredItem = registeredItems.find((item) => item?.id === formField?.contentLink?.id?.toString());

				for (const selectionItem of selectionItems) {
					sendItemTrackingEvent(eventTrigger, selectionItem, registeredItem?.trackingRef);
				}
			}

			return _this;
		},
	};

	return _this;
}

function sendItemTrackingEvent(
	eventTrigger: FormTrackingEventTrigger,
	selectionItem: ISelectionItemContent,
	trackingRef: RefObject<HTMLElement> | undefined
) {
	if (!trackingRef?.current) {
		return;
	}

	const trackingProps = getTrackingPropertiesFromCMSBlock(selectionItem);
	const itemTrackingInformation: ITrackingInformation = trackingProps.cmsTrackingInformation ?? {};

	itemTrackingInformation.value = undefined;

	if (!itemTrackingInformation.label?.length) {
		itemTrackingInformation.label = selectionItem.value;
	}

	// Unset conversion ID if eventTrigger is not submit
	if (eventTrigger !== FormTrackingEventTrigger.Submit && itemTrackingInformation.conversionId) {
		itemTrackingInformation.conversionId = undefined;
	}

	doTrack(CustomTrackingEvent.FromTarget(trackingRef.current, eventTrigger), itemTrackingInformation);
}

function getSelectFormFields(
	salesforceForm: IFormSalesforceBlockContent,
	formFieldsAbove?: IFormFieldResponse[],
	formFieldsBelow?: IFormFieldResponse[]
) {
	const formFields: IFormFieldResponse[] = [];

	if (salesforceForm?.formFields) {
		formFields.push(...salesforceForm.formFields);
	}

	if (formFieldsAbove) {
		formFields.push(...formFieldsAbove);
	}

	if (formFieldsBelow) {
		formFields.push(...formFieldsBelow);
	}

	return (
		formFields
			?.map((response: IFormFieldResponse) => response?.contentLink?.expanded)
			?.filter(
				(formField: IFormFieldContent) =>
					!!formField?.displayMode &&
					(Object.values(SelectFormFieldDisplayMode) as FormFieldDisplayMode[]).includes(formField?.displayMode)
			)
			?.map((field: IFormFieldContent) => field as ISelectFormFieldContent) ?? []
	);
}

function getRelevantSelectionItems(formField: ISelectFormFieldContent) {
	return (
		formField?.selectionItems
			?.map((response) => response?.contentLink?.expanded)
			?.filter((item) => item?.isRelevantTrackingComponent) ?? []
	);
}

function isItemAccepted(selectionItem: ISelectionItemContent, formValues: Record<string, string> | undefined) {
	if (!formValues) {
		return false;
	}

	return Object.values(formValues).some((value) => value?.includes(selectionItem?.value));
}
