'use client';

import { IPageLanguage } from '@/interfaces/coreInformation';
import { FC, useEffect } from 'react';

interface ICookieSettingsTextSnippetTriggerProps {
	id?: string;
	language?: IPageLanguage;
}

export const CookieSettingsTextSnippetTrigger: FC<ICookieSettingsTextSnippetTriggerProps> = ({ language, id }) => {
	useEffect(() => {
		const containerElement = id ? document.getElementById(id) : null;

		const cookieSettingsElements = containerElement?.querySelectorAll('.cookieSettingsTrigger') || [];

		const handleClick = () => {
			globalThis.utag?.ext?.showConsentPreferencesSecure(language?.name || 'en');
		};

		cookieSettingsElements.forEach((element) => element.addEventListener('click', handleClick));

		return () => {
			cookieSettingsElements.forEach((element) => element.removeEventListener('click', handleClick));
		};
	});

	return null;
};
