'use client';

import { ITrackingSettingsContent } from '@/interfaces/settings';
import { createContext, useContext } from 'react';

export interface IGlobalTracking {
	isTealiumReady: boolean;
	utag: typeof globalThis.utag;
	trackingSettings?: ITrackingSettingsContent;
}

export const GlobalTrackingContext = createContext<IGlobalTracking>({
	isTealiumReady: false,
	utag: undefined,
	trackingSettings: undefined,
});

export const useGlobalTracking = () => {
	return useContext(GlobalTrackingContext);
};
