'use client';
import { IExecutionQualityReportTable } from '@/components/block-templates/ExecutionQualityReportBlockTemplate/ExecutionQualityReportBlockTemplate';
import { FormMessage } from '@/components/block-templates/Forms/FormContainerBlockTemplate/components/FormMessage';
import { Button } from '@/components/core/Button/Button';
import { TextField } from '@/components/shared/Forms/TextField';
import { TextSnippet } from '@/components/shared/TextSnippet/TextSnippet';
import { Tracked } from '@/components/shared/tracking/Tracked';
import { DownloadIcon } from '@/icons/DownloadIcon';
import { TextFormFieldDisplayMode } from '@/interfaces/blocks/forms';
import { IPageData } from '@/interfaces/coreInformation';
import { VStack, VstackProps } from '@/styled-system/jsx';
import { ExecutionQualityReportItemType } from '@/types/executionQualityReports';
import { EventCategory, ITrackedBlockInformation, defaultConversionIdByEventCategory } from '@/types/tracking';
import { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface IExecutionQualityReportItemProps extends VstackProps {
	tableId: string;
	type: ExecutionQualityReportItemType;
	successMessage: string;
	errorMessage: string;
	mandatoryErrorMessage: string;
	blockId: number;
	pageData: IPageData;
	trackedBlockInformation?: ITrackedBlockInformation;
	conversionId?: string;
	reportTable: IExecutionQualityReportTable;
}

export const ExecutionQualityReportItem = ({
	reportTable,
	successMessage,
	errorMessage,
	mandatoryErrorMessage,
	type,
	tableId,
	blockId,
	pageData,
	trackedBlockInformation,
	conversionId,
	...rest
}: IExecutionQualityReportItemProps) => {
	const methods = useForm<{ input: string }>();
	const [submissionState, setSubmissionState] = useState<'success' | 'error' | undefined>(undefined);
	const buttonRef = useRef<HTMLButtonElement>(null);

	const {
		reportDownloadFileName,
		sectionContent,
		reportButtonHoverText,
		reportButtonText,
		inputFieldName,
		inputFieldPlaceholder,
		inputFieldDescription,
	} = reportTable;
	const filename = reportDownloadFileName || 'execution_quality_report.csv';

	const onSubmit = async (data: { input: string }) => {
		let url = `/api/ExecutionQualityReport/${tableId}/`;

		if (data.input) {
			url += `?input=${data.input}`;
		}

		const response = await fetch(url);

		if (response.ok) {
			const responseBody = await response.blob();

			const url = window.URL.createObjectURL(responseBody);
			const link = document.createElement('a');

			link.href = url;
			link.setAttribute('download', filename);

			document.body.appendChild(link);

			link.click();

			setSubmissionState('success');
		} else {
			setSubmissionState('error');
		}
	};

	return (
		<VStack gap={3} alignItems="flex-start" {...rest}>
			{sectionContent && (
				<TextSnippet dataId={`${blockId}-text-snippet`} pageData={pageData} content={sectionContent} />
			)}

			<FormProvider {...methods}>
				{type === 'isin' && (
					<TextField
						displayMode={TextFormFieldDisplayMode.TEXT}
						fieldLabel={inputFieldName}
						translations={{}}
						placeholder={inputFieldPlaceholder}
						helperText={inputFieldDescription}
						fieldName={'input'}
						textMaxLength={12}
						isMandatory={true}
						mandatoryErrorMessage={mandatoryErrorMessage}
						isHidden={false}
						makeReadOnlyIfHasInitialValue={false}
						validationPattern={/[a-zA-Z0-9]{12}/}
						title={inputFieldDescription}
						id={0}
					/>
				)}
				{type === 'date' && (
					<TextField
						displayMode={TextFormFieldDisplayMode.DATE}
						fieldLabel={inputFieldName}
						translations={{}}
						placeholder={inputFieldPlaceholder}
						helperText={inputFieldDescription}
						fieldName={'input'}
						isMandatory={true}
						mandatoryErrorMessage={mandatoryErrorMessage}
						isHidden={false}
						makeReadOnlyIfHasInitialValue={false}
						title={inputFieldDescription}
						id={0}
					/>
				)}
			</FormProvider>
			{type !== 'none' && (
				<Tracked
					trackingInformation={{
						click: {
							action: 'download',
							label: filename,
							techCategory: 'link',
							conversionId: conversionId || defaultConversionIdByEventCategory[EventCategory.MEDIA],
							category: EventCategory.MEDIA,
							mediaValue: filename,
							mediaType: 'document',
							mediaSource: 'corpweb-cms',
						},
					}}
					trackedBlockInformation={trackedBlockInformation}
					trackingElementRef={buttonRef}
				>
					<Button
						ref={buttonRef}
						isLoading={methods.formState.isSubmitting}
						title={reportButtonHoverText}
						onClick={methods.handleSubmit(async (data) => {
							console.log('Submitting with data:', data);
							await onSubmit(data);
						})}
						rightIcon={<DownloadIcon />}
					>
						{reportButtonText}
					</Button>
				</Tracked>
			)}

			{submissionState === 'success' && <FormMessage variant="success" content={successMessage}></FormMessage>}
			{submissionState === 'error' && <FormMessage variant="error" content={errorMessage}></FormMessage>}
		</VStack>
	);
};
