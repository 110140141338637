'use client';

import { TextField } from '@/components/shared/Forms/TextField';
import { ITextFormFieldResponse } from '@/interfaces/blocks/forms';
import { FC } from 'react';

export interface ITextFormFieldProps {
	field: ITextFormFieldResponse;
	translations: { [key: string]: string };
	// eslint-disable-next-line @typescript-eslint/ban-types
	formPersonalizationHandler?: Function;
}

export const TextFormField: FC<ITextFormFieldProps> = ({ field, translations, formPersonalizationHandler }) => {
	if (!field?.contentLink?.expanded) {
		return;
	}

	const {
		displayMode,
		fieldLabel,
		placeholderText,
		fieldName,
		textMaxLength,
		isMandatory,
		mandatoryErrorMessage,
		isHidden,
		makeReadOnlyIfHasInitialValue,
	} = field.contentLink.expanded;

	return (
		<TextField
			formPersonalizationHandler={formPersonalizationHandler}
			translations={translations}
			id={field.contentLink.id}
			displayMode={displayMode}
			fieldLabel={fieldLabel}
			placeholder={placeholderText}
			fieldName={fieldName}
			textMaxLength={textMaxLength}
			isMandatory={isMandatory}
			mandatoryErrorMessage={mandatoryErrorMessage}
			isHidden={isHidden}
			makeReadOnlyIfHasInitialValue={makeReadOnlyIfHasInitialValue}
		/>
	);
};
