'use client';

import { DocumentIcon } from '@/icons/DocumentIcon';
import { FileError } from '@zag-js/file-utils';
import { css } from '@/styled-system/css';
import { FileUpload as ArkFileUpload, FileUploadFileAcceptDetails, FileUploadFileRejectDetails } from '@ark-ui/react';
import React, { forwardRef, useEffect, useState } from 'react';
import { Text } from '@/components/core/Text/Text';
import { Flex, styled } from '@/styled-system/jsx';
import { translate, TranslationLabelValues } from '@/utils/i18n/translation-labels/translationLabels';
import { AlertIcon } from '@/icons/AlertIcon';
import { IFormFieldFileUploadContent } from '@/interfaces/blocks/forms';
import { FieldError, FieldValues, UseFormTrigger } from 'react-hook-form';
import {
	getConstraintsLabels,
	getFormFileUploadErrorsLabel,
} from '@/components/shared/Forms/FileUploadFormField/FileUploadFormField.utils';
import { RejectedFileUploadItem } from '@/components/shared/Forms/FileUploadFormField/components/RejectedFileUploadItem';
import { getIconByMimeType } from '@/const/icon';
import { MimeType } from '@/enums/MimeType';
import { Button } from '@/components/core/Button/Button';
import { TrashIcon } from '@/icons/TrashIcon';
import { fileUpload, fileUploadItem } from '@/components/shared/Forms/FileUploadFormField/components/FileUpload.recipe';
import { BadgeIcon } from '@/icons/BadgeIcon';
import { IFormFieldFileUploadValidations } from '@/components/shared/Forms/FileUploadFormField/FileUploadFormField';
import { link } from '@/styled-system/recipes';

export type FileUploadError = 'FIELD_EMPTY' | 'FILES_TOO_LARGE' | FileError;

interface IFormFileUploadDropdownContentProps extends Partial<IFormFieldFileUploadContent> {
	setFormValue: (fieldName: string, value: File[], options: any) => void;
	setFormError: (fieldName: string, error: FieldError) => void;
	translations: TranslationLabelValues;
	disabled: boolean;
	formValue: File[];
	formErrors?: FieldError;
	trigger: UseFormTrigger<FieldValues>;
	isFieldTouched: boolean;
	fieldValue: File[];
	validations: IFormFieldFileUploadValidations;
	isSubmitting: boolean;
}

interface FileRejection {
	file: File;
	errors: FileError[];
}

export const FileUpload = forwardRef<HTMLDivElement, IFormFileUploadDropdownContentProps>(
	(
		{
			fieldLabel,
			placeholderText,
			allowedFileTypes,
			translations,
			setFormValue,
			fieldName,
			maximumFileSize,
			isMandatory,
			maximumNumberOfFiles,
			allowedTotalSizeOnMultiple,
			validations,
			isSubmitting,
			allowedFilesInfoText,
		},
		ref
	) => {
		const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
		const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
		const [isDragging, setIsDragging] = useState(false);
		const [errors, setErrors] = useState<Array<string> | undefined>([]);

		const validateFilesValue = (value: File[]) => {
			const newErrors = [] as string[];

			for (const [_key, validationMethod] of Object.entries(validations)) {
				const validationValue = validationMethod(value, []);

				if (typeof validationValue === 'string') {
					newErrors.push(validationValue);
				}
			}

			setErrors(newErrors);
		};

		const onFilesChange = (e: FileUploadFileAcceptDetails) => {
			setAcceptedFiles(e.files);
			validateFilesValue(e.files);
		};

		const onFilesReject = (e: FileUploadFileRejectDetails) => {
			setRejectedFiles((prevRejectedFiles) => [...prevRejectedFiles, ...e.files]);
		};

		const onRemoveRejectedFile = (index: number) => {
			setRejectedFiles((prevRejectedFiles) => prevRejectedFiles.filter((_, i) => i !== index));
		};

		const classes = fileUpload();
		const acceptedItemsClasses = fileUploadItem({ state: 'accepted' });

		const optionalLabel = translations ? ` (${translate(translations, 'form.optional', 'optional')})` : ` (optional)`;

		useEffect(() => {
			setFormValue(fieldName!, acceptedFiles, { shouldTouch: true });
		}, [acceptedFiles]);

		useEffect(() => {
			const handleDragEnter = (event: DragEvent) => {
				event.preventDefault();
				event.stopPropagation();

				if (event.dataTransfer?.items && event.dataTransfer.items.length > 0) {
					setIsDragging(true);
				}
			};

			const handleDragLeave = (event: DragEvent) => {
				event.preventDefault();
				event.stopPropagation();

				// Next special case handling is required due to nature of drag events
				// The dragleave event is fired when a dragged element or text selection leaves a valid drop target.
				// That means that the event is fired when hovered away from our dropzone, removing it's border focus indicator
				// We only need to check if this event is called when we've exited the window, in which case we should remove the border focus indicator
				// This is done by checking if the clientX and clientY are less than or equal to 0
				if (event.clientX <= 0 || event.clientY <= 0) {
					setIsDragging(false);
				}
			};

			window.addEventListener('dragenter', handleDragEnter);
			window.addEventListener('dragleave', handleDragLeave);

			return () => {
				window.removeEventListener('dragenter', handleDragEnter);
				window.removeEventListener('dragleave', handleDragLeave);
			};
		}, []);

		useEffect(() => {
			if (isSubmitting) {
				validateFilesValue(acceptedFiles);
			}
		}, [isSubmitting]);

		return (
			<>
				<div className={classes.fieldInformation}>
					{fieldLabel && (
						<Text size="lg" fontWeight={700}>
							{`${fieldLabel} ${isMandatory ? '*' : optionalLabel}`}
						</Text>
					)}
					{placeholderText && <Text>{placeholderText}</Text>}
				</div>
				<ArkFileUpload.Root
					ref={ref}
					accept={allowedFileTypes}
					className={classes.root}
					maxFiles={Infinity}
					maxFileSize={maximumFileSize ? maximumFileSize * 1000000 : undefined}
					onFileAccept={onFilesChange}
					onFileReject={onFilesReject}
					allowDrop
				>
					<ArkFileUpload.Dropzone
						onDropCapture={() => setIsDragging(false)}
						data-window-dragging={isDragging}
						className={classes.dropzone}
					>
						<Flex className={classes.infoContainer}>
							<DocumentIcon width="56px" height="56px" />
							<Text textAlign="center" size="md">
								{translate(translations, 'forms.help.fileupload-text')}
								&nbsp;
								<styled.span className={link({ size: 'md' })} display="inline">
									{translate(translations, 'forms.help.fileupload-linktext')}
								</styled.span>
							</Text>
							<div className={classes.constraints}>
								<Text size="sm" textAlign="center">
									{getConstraintsLabels(
										{
											maximumFileSize,
											maximumNumberOfFiles,
											allowedTotalSizeOnMultiple,
										},
										translations,
										allowedFilesInfoText
									)}
								</Text>
							</div>
						</Flex>
					</ArkFileUpload.Dropzone>
					{errors && errors.length > 0 && (
						<Flex gap={1} marginTop={3}>
							<AlertIcon className={css({ color: 'trafficLights.error.default' })} width="24px" height="24px" />
							<Text color="trafficLights.error.default" size="sm">
								{getFormFileUploadErrorsLabel(errors as FileUploadError[], translations)}
							</Text>
						</Flex>
					)}
					<Flex className={classes.uploadedItemsContainer} marginBlock={6}>
						<ArkFileUpload.ItemGroup className={css({ width: 'full', _empty: { display: 'none' } })}>
							{(files) =>
								files.map((file, index) => {
									const mimeIcon = getIconByMimeType(file.type as MimeType);

									return (
										<ArkFileUpload.Item
											className={acceptedItemsClasses.uploadedItemWrapper}
											key={`${fieldName}-accepted-file-upload-file-${index}`}
											file={file}
										>
											<Button
												as={ArkFileUpload.ItemDeleteTrigger}
												title={translate(translations, 'forms.help.fileupload-remove-button-title')}
												leftIcon={<TrashIcon />}
												variant="unstyled"
												size="medium"
												className="peer"
											/>
											<div className={acceptedItemsClasses.uploadedItemContent}>
												<span>{mimeIcon}</span>
												<div className={acceptedItemsClasses.uploadedItemTextContent}>
													<Text
														title={file.name}
														color="text.regular.default"
														size="sm"
														lineClamp={1}
														overflowWrap="anywhere"
													>
														{file.name}
													</Text>
													<Text color="text.regular.subtitle" size="xs" lineClamp={1} overflowWrap="anywhere">
														{(file.size / (1024 * 1024)).toFixed(1)} MB
													</Text>
												</div>
												<span>
													<BadgeIcon className={acceptedItemsClasses.stateIcon} />
												</span>
											</div>
										</ArkFileUpload.Item>
									);
								})
							}
						</ArkFileUpload.ItemGroup>

						{rejectedFiles && rejectedFiles.length > 0 && (
							<ul>
								{rejectedFiles.map((fileRejection, index) => (
									<RejectedFileUploadItem
										key={`${fieldName}-rejected-file-upload-file-${index}`}
										file={fileRejection.file}
										index={index}
										errors={fileRejection.errors}
										onRemoveItem={onRemoveRejectedFile}
										translations={translations}
									/>
								))}
							</ul>
						)}
					</Flex>
				</ArkFileUpload.Root>
			</>
		);
	}
);
