import { SVGAttributes } from 'react';

export const LinkedinIcon = (props: SVGAttributes<SVGElement>) => (
	<svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M17.1789 19.2419C17.1644 17.9681 18.1777 16.9186 19.4516 16.9041C19.5168 16.9041 19.5891 16.9041 19.6543 16.9041C20.8775 16.9909 21.8329 18.0043 21.8402 19.2347V26.4872H26.5014V19.2347C26.5014 15.1235 24.4314 12.9087 21.5289 12.9087C19.951 12.8508 18.4093 13.3575 17.1789 14.3346V13.01H12.561V26.4872H17.1789V19.2347V19.2419Z"
			fill="currentColor"
		></path>
		<path d="M10.4403 13.0245H5.77905V26.5017H10.4403V13.0245Z" fill="currentColor"></path>
		<path
			d="M8.21099 11.2077C8.21099 11.2077 8.27613 11.2077 8.30508 11.2077H8.37022C9.90468 11.2439 11.1858 10.0279 11.222 8.49346C11.222 8.45004 11.222 8.39937 11.222 8.35594C11.2654 6.82873 10.0567 5.5476 8.52946 5.50417C8.47879 5.50417 8.42089 5.50417 8.37022 5.50417C6.85748 5.43903 5.56912 6.61159 5.50398 8.13156C5.50398 8.19671 5.50398 8.25461 5.50398 8.31975C5.45331 9.86144 6.66206 11.157 8.21099 11.2077Z"
			fill="currentColor"
		></path>
	</svg>
);
