import { SVGAttributes } from 'react';

export const WiFiIcon = (props: SVGAttributes<SVGElement>) => (
	<svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M4.06348 4.06348V27.9365H27.9365V4.06348H4.06348ZM26.4127 26.4127H5.58729V5.58729H26.4127V26.4127Z"
			fill="currentColor"
		></path>
		<path
			d="M8.94475 14.3187C10.7327 12.2819 13.3028 11.1136 16 11.1136C18.6971 11.1136 21.2622 12.2819 23.0552 14.3187C23.2076 14.4914 23.4159 14.5778 23.6292 14.5778C23.807 14.5778 23.9898 14.5168 24.132 14.3898C24.447 14.1105 24.4774 13.633 24.2032 13.313C22.1257 10.9511 19.139 9.5949 16.0051 9.5949C12.8711 9.5949 9.88443 10.9511 7.80697 13.313C7.5276 13.6279 7.55808 14.1105 7.87808 14.3898C8.193 14.6692 8.67554 14.6387 8.9549 14.3187H8.94475Z"
			fill="currentColor"
		></path>
		<path
			d="M15.9949 12.6882C13.6482 12.6882 11.4438 13.7244 9.9403 15.5327C9.67109 15.8578 9.71681 16.3352 10.0368 16.6044C10.3619 16.8736 10.8393 16.8279 11.1086 16.5079C12.3225 15.0501 14.1003 14.212 15.9949 14.212C17.8895 14.212 19.6673 15.0501 20.8813 16.5079C21.0336 16.6908 21.247 16.7822 21.4654 16.7822C21.6381 16.7822 21.8108 16.7263 21.953 16.6044C22.2781 16.3352 22.3187 15.8527 22.0495 15.5327C20.546 13.7244 18.3416 12.6882 15.9949 12.6882Z"
			fill="currentColor"
		></path>
		<path
			d="M15.9949 15.6851C14.4508 15.6851 12.9879 16.4368 12.0787 17.6965C11.8349 18.0368 11.9111 18.5143 12.2514 18.7581C12.5917 19.0019 13.0692 18.9257 13.313 18.5854C13.9327 17.7219 14.9333 17.2089 15.9898 17.2089C17.0463 17.2089 18.047 17.7219 18.6667 18.5854C18.814 18.7936 19.0476 18.9003 19.2863 18.9003C19.4387 18.9003 19.5962 18.8546 19.7333 18.7581C20.0736 18.5143 20.1498 18.0368 19.906 17.6965C18.9968 16.4368 17.534 15.6851 15.9898 15.6851H15.9949Z"
			fill="currentColor"
		></path>
		<path
			d="M16.0101 18.7174C15.1517 18.7174 14.4559 19.4133 14.4559 20.2717C14.4559 21.1301 15.1517 21.826 16.0101 21.826C16.8686 21.826 17.5644 21.1301 17.5644 20.2717C17.5644 19.4133 16.8686 18.7174 16.0101 18.7174Z"
			fill="currentColor"
		></path>
	</svg>
);
