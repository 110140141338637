import { sva } from '@/styled-system/css';

export const articleTeaserRecipe = sva({
	slots: [
		'container',
		'contentWrapper',
		'imageContainer',
		'contentContainer',
		'textContainer',
		'dateAndCategoryContainer',
		'dateLabel',
		'categoryLabel',
		'titleContainer',
		'authorContainer',
		'link',
	],
	base: {
		container: {
			containerType: 'inline-size',
			display: 'block',
			width: '100%',
			height: '100%',
			boxShadow: 'subtleBottom',
			borderRadius: 'md',
			transition: 'color 0.3s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out',
			position: 'relative',
			_hover: {
				boxShadow: 'strongBottom',
				'& [data-part="article-teaser-title"]': {
					textDecoration: 'underline',
				},
				'& img': {
					transform: 'scale(1.05)',
				},
			},
		},
		contentWrapper: {
			width: '100%',
			height: '100%',
			minHeight: '300px',
			display: 'grid',
			'@container (width <= 700px)': {
				gridTemplateRows: 'auto 1fr',
			},
			'@container (width > 700px)': {
				gridTemplateColumns: '50% 50%',
			},
		},
		imageContainer: {
			borderTopRadius: 'md',
			overflow: 'hidden',
			position: 'relative',
			minHeight: '220px',
			'& img': {
				height: '100%',
				objectFit: 'cover',
				width: '100%',
				transition: 'transform 0.3s ease-out',
				transform: 'scale(1.01)',
				'@container (width > 700px)': {
					position: 'absolute',
					height: '100%',
					width: '100%',
				},
				'@container (width < 700px)': {
					height: '220px',
				},
			},
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
			paddingInline: 6,
			paddingTop: 4,
			paddingBottom: 6,
			gap: 4,
			justifyContent: 'center',
			'@container (width <= 700px)': {
				justifyContent: 'space-between',
			},
			'@container (width > 700px)': {
				paddingInline: 12,
				paddingBlock: 8,
			},
		},
		textContainer: {
			display: 'flex',
			flexDirection: 'column',
			gap: 1,
		},
		dateAndCategoryContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			gap: 2,
			marginBottom: 1,
		},
		authorContainer: {
			display: 'flex',
			gap: 2,
			marginTop: 1,
			marginBottom: 2,
			alignItems: 'center',
			'& img': {
				width: '18px',
				height: '18px',
				borderRadius: '50%',
				border: 'solid',
				borderWidth: '2px',
				borderColor: 'surface.static.secondary',
				objectFit: 'cover',
			},
		},
		titleContainer: {
			fontSize: '1.25rem',
			fontWeight: '700',
			lineHeight: '130%',
		},
		link: {
			transition: 'color 0.3s ease-out',
			_before: {
				content: '""',
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
			},
		},
	},
	defaultVariants: {
		variant: 'Secondary',
		imagePosition: 'left',
	},
	variants: {
		variant: {
			Secondary: {
				container: {
					backgroundColor: 'surface.static.secondary',
					color: 'text.regular.default',
				},
				dateAndCategoryContainer: {
					color: 'text.regular.subtitle',
				},
				authorContainer: {
					color: 'text.regular.subtitle',
					'& img': {
						borderColor: 'surface.static.secondary',
					},
				},
			},
			Primary: {
				container: {
					backgroundColor: 'surface.static.primary',
					color: 'text.regular.default',
				},
				dateAndCategoryContainer: {
					color: 'text.regular.subtitle',
				},
				authorContainer: {
					color: 'text.regular.subtitle',
					'& img': {
						borderColor: 'surface.static.tertiary',
					},
				},
			},
			Inverted: {
				container: {
					backgroundColor: 'surface.static.primaryInverted',
					color: 'text.inverted.default',
				},
				dateAndCategoryContainer: {
					color: 'text.inverted.subtitle',
				},
				authorContainer: {
					color: 'text.inverted.subtitle',
					'& img': {
						borderColor: 'surface.interactive.card.secondary',
					},
				},
			},
		},
		imagePosition: {
			left: {
				imageContainer: {
					'@container (width > 700px)': {
						gridColumnStart: 1,
						gridRowStart: 1,
						borderLeftRadius: 'md',
						borderTopRightRadius: 'unset',
					},
				},
				contentContainer: {
					'@container (width > 700px)': {
						gridColumnStart: 2,
						gridRowStart: 1,
					},
				},
			},
			right: {
				imageContainer: {
					'@container (width > 700px)': {
						gridColumnStart: 2,
						gridRowStart: 1,
						borderRightRadius: 'md',
						borderTopLeftRadius: 'unset',
					},
				},
				contentContainer: {
					'@container (width > 700px)': {
						gridColumnStart: 1,
						gridRowStart: 1,
					},
				},
			},
		},
	},
});
