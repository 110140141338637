import { SVGAttributes } from 'react';

export const ClearIcon = (props: SVGAttributes<SVGElement>) => (
	<svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			clip-rule="evenodd"
			d="M9.04126 21.8819L10.1181 22.9587L15.9999 17.082L21.8819 22.9587L22.9587 21.8819L17.0768 16L22.9587 10.1181L21.8819 9.04126L16 14.9232L10.1181 9.04126L9.04126 10.1181L14.9232 16L9.04126 21.8819Z"
			fill="currentColor"
			fill-rule="evenodd"
		></path>
		<path
			clip-rule="evenodd"
			d="M29.5 16.0002C29.5 23.4561 23.4558 29.5002 16 29.5002C8.54416 29.5002 2.5 23.4561 2.5 16.0002C2.5 8.5444 8.54416 2.50024 16 2.50024C23.4558 2.50024 29.5 8.5444 29.5 16.0002ZM16 28.0002C22.6274 28.0002 28 22.6277 28 16.0002C28 9.37283 22.6274 4.00024 16 4.00024C9.37258 4.00024 4 9.37283 4 16.0002C4 22.6277 9.37258 28.0002 16 28.0002Z"
			fill="currentColor"
			fill-rule="evenodd"
		></path>
	</svg>
);
