import { CookieSettingsTextSnippetTrigger } from '@/components/shared/TextSnippet/CookieSettingsTextSnippetTrigger';
import { DailyTelegramContentRenderer } from '@/components/shared/TextSnippet/components/DailyTelegramContentRenderer/DailyTelegramContentRenderer';
import { DefaultContentRenderer } from '@/components/shared/TextSnippet/components/DefaultContentRenderer/DefaultContentRenderer';
import { TextSnippetTrackingWrapper } from '@/components/shared/TextSnippet/components/TextSnippetTrackingWrapper/TextSnippetTrackingWrapper';
import { TextSnippetVariants, textSnippet } from '@/components/shared/TextSnippet/textSnippet.recipe';
import { siteName } from '@/constants/global';
import { BackgroundColorVariant } from '@/enums/ColorMode';
import { ContentType } from '@/enums/ContentType';
import { IPageData, IPageLanguage } from '@/interfaces/coreInformation';
import { BoxProps, styled } from '@/styled-system/jsx';
import { FC } from 'react';
import '/node_modules/flag-icons/css/flag-icons.min.css';

interface ITextSnippetProps extends Omit<BoxProps, 'backgroundColor'> {
	id?: string;
	dataId: string;
	pageData?: IPageData;
	language?: IPageLanguage;
	content?: string;
	blockName?: string;
	parentBackgroundColor?: BackgroundColorVariant;
	preventAutomatedTracking?: boolean;
	shouldBindCookieSettingsTrigger?: boolean;
}

export const TextSnippet: FC<ITextSnippetProps> = ({
	id,
	dataId,
	pageData,
	language,
	content,
	blockName,
	parentBackgroundColor = BackgroundColorVariant.PRIMARY,
	preventAutomatedTracking,
	shouldBindCookieSettingsTrigger = true,
	...rest
}) => {
	if (!content) {
		return null;
	}

	const className = textSnippet({ parentBackgroundColor, site: siteName as TextSnippetVariants['site'] });

	const isDailyTelegramPage = pageData?.pageContentType === ContentType.DailyTelegramPage;

	const ContentRenderer = isDailyTelegramPage ? DailyTelegramContentRenderer : DefaultContentRenderer;

	return (
		<TextSnippetTrackingWrapper dataId={dataId} pageData={pageData} preventAutomatedTracking={preventAutomatedTracking}>
			<ContentRenderer content={content} blockName={blockName}>
				{({ children }) => (
					<styled.div id={id} data-id={dataId} className={className} {...rest}>
						{children}
					</styled.div>
				)}
			</ContentRenderer>
			{shouldBindCookieSettingsTrigger && <CookieSettingsTextSnippetTrigger id={id} language={language} />}
		</TextSnippetTrackingWrapper>
	);
};
