'use client';

import { Button } from '@/components/core/Button/Button';
import { Text } from '@/components/core/Text/Text';
import { CloseIcon } from '@/icons/CloseIcon';
import { WarningIcon } from '@/icons/WarningIcon';
import { TextFormFieldDisplayMode } from '@/interfaces/blocks/forms';
import { css } from '@/styled-system/css';
import { Box } from '@/styled-system/jsx';
import { input } from '@/styles/theme/input';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import { InputHTMLAttributes, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	displayMode?: TextFormFieldDisplayMode;
	label?: string;
	variant?: 'sm' | 'md';
	isError?: boolean;
	isMandatory?: boolean;
	error?: FieldError;
	helperText?: string;
	clearInput?: () => void;
	translations?: TranslationLabelValues;
}

enum InputType {
	TEXT = 'text',
	EMAIL = 'email',
	NUMBER = 'number',
	PHONE_NUMBER = 'tel',
	DATE = 'date',
}

const getInputType = (displayMode?: TextFormFieldDisplayMode) => {
	switch (displayMode) {
		case TextFormFieldDisplayMode.TEXT:
			return InputType.TEXT;
		case TextFormFieldDisplayMode.EMAIL:
			return InputType.EMAIL;
		case TextFormFieldDisplayMode.NUMBER:
			return InputType.NUMBER;
		case TextFormFieldDisplayMode.PHONE_NUMBER:
			return InputType.PHONE_NUMBER;
		case TextFormFieldDisplayMode.DATE:
			return InputType.DATE;
		default:
			return InputType.TEXT;
	}
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			displayMode,
			label,
			placeholder,
			value,
			onChange,
			clearInput,
			variant,
			isError,
			error,
			disabled,
			readOnly,
			isMandatory,
			hidden,
			translations,
			helperText,
			...rest
		},
		ref
	) => {
		const classes = input.raw({ variant, disabled, readOnly, isError, hidden });
		const optionalLabel = translations ? ` (${translate(translations, 'form.optional', 'optional')})` : ` (optional)`;

		const type = getInputType(displayMode);

		return (
			<Box className={css(classes.root)}>
				{Boolean(label?.length) && (
					<label className={css(classes.label)}>{`${label} ${isMandatory ? ' *' : optionalLabel}`}</label>
				)}
				<Box className={css(classes.inputWrapper)}>
					<input
						type={type}
						className={css(classes.input)}
						placeholder={placeholder}
						onChange={onChange}
						disabled={disabled}
						readOnly={readOnly}
						value={value}
						required={isMandatory}
						hidden={hidden}
						ref={ref}
						{...rest}
					/>
					{Boolean(value) && Boolean(!disabled && !readOnly) && (
						<Button
							rootProps={classes.clearButton}
							variant="unstyled"
							size="unsized"
							onClick={clearInput}
							leftIcon={<CloseIcon color="icons.inverted.default" width="16px" height="16px" />}
							tabIndex={-1}
						/>
					)}
				</Box>
				{helperText && !isError && (
					<Box className={css(classes.inputFooter)}>
						<Text className={css(classes.helperText)} size="xxs">
							{helperText}
						</Text>
					</Box>
				)}
				{isError && error?.message && (
					<Box className={css(classes.inputFooter)}>
						<WarningIcon className={css(classes.error)} width={12} height={12} />
						<Text className={css(classes.helperText)} size="xxs">
							{error?.message}
						</Text>
					</Box>
				)}
			</Box>
		);
	}
);
