import { toIdentifier } from '@/components/block-templates/Jobs/JobFilterBlock/JobFilterBlockTemplate.utils';
import { IJob } from '@/components/shared/Jobs/Jobs.types';

export const filterJobResults = (jobs: Array<IJob>, query: Record<string, string[]>) => {
	let filteredJobs = jobs;

	if (query?.search) {
		const searchStrings = query.search[0];

		filteredJobs = filteredJobs.filter((job) => {
			if (performFuzzyComparison(searchStrings, job.title)) {
				return true;
			}

			if (performFuzzyComparison(searchStrings, job.locationDescription)) {
				return true;
			}

			if (performFuzzyComparison(searchStrings, job.jobOrgGf)) {
				return true;
			}

			return false;
		});
	}

	if (query?.locations) {
		filteredJobs = filteredJobs.filter((job) => query.locations.includes(toIdentifier(job.locationDescription)));
	}

	if (query?.departments) {
		filteredJobs = filteredJobs.filter((job) => query.departments.includes(toIdentifier(job.jobOrgGf)));
	}

	return filteredJobs;
};

export const areJobFiltersApplied = (query: Record<string, string[]>) => {
	return Boolean(query?.search || query?.locations || query?.departments);
};

function preprocessString(str: string) {
	return str.toLowerCase().replace(/[^a-z0-9]/g, '');
}

function performFuzzyComparison(str1: string, str2: string) {
	const processedSearchString = preprocessString(str1);
	const processedText = preprocessString(str2);

	return processedText.includes(processedSearchString);
}
