'use client';

import { FormTrackingEventTrigger } from '@/components/block-templates/Forms/FormContainerBlockTemplate/FormContainerBlockTemplate.tracking';
import { CheckboxGroup } from '@/components/core/Checkbox';
import { RadioButtons } from '@/components/core/RadioButtons';
import { FormDropdown } from '@/components/shared/Forms/FormDropdown';
import { ITrackedContainerProps } from '@/components/shared/TrackedContainer/TrackedContainer';
import { Tracked } from '@/components/shared/tracking/Tracked';
import {
	ISelectFormFieldContent,
	ISelectFormFieldResponse,
	SelectFormFieldDisplayMode,
} from '@/interfaces/blocks/forms';
import { ITrackingInformation } from '@/types/tracking';
import { getTrackingPropertiesFromCMSBlock } from '@/utils/tracking/tracking';
import { FC, Fragment, useEffect, useRef } from 'react';
import { TranslationLabelValues } from '@/utils/i18n/translation-labels/translationLabels';
import { Controller, useFormContext } from 'react-hook-form';
import { useFormTrackingSelectionItems } from '@/components/shared/Forms/FormTrackingProvider';

export interface ISelectFormFieldProps {
	field: ISelectFormFieldResponse;
	translations: TranslationLabelValues;
	// eslint-disable-next-line @typescript-eslint/ban-types
	formPersonalizationHandler?: Function;
}

const getFormElement = (displayMode?: SelectFormFieldDisplayMode) => {
	switch (displayMode) {
		case SelectFormFieldDisplayMode.CHECKBOX_1_ROW:
			return CheckboxGroup;
		case SelectFormFieldDisplayMode.RADIO_BUTTONS:
			return RadioButtons;
		case SelectFormFieldDisplayMode.DROPDOWN_SINGLE:
			return FormDropdown;
		case SelectFormFieldDisplayMode.DROPDOWN_MULTI:
			return FormDropdown;
		default:
			return Fragment;
	}
};

const getTrackingProps = (selectionContainer: ISelectFormFieldContent): Partial<ITrackedContainerProps> => {
	const trackingProps = getTrackingPropertiesFromCMSBlock(selectionContainer);
	const conversionId = trackingProps.cmsTrackingInformation?.conversionId;

	// Register Tracking Props. The Props will be set correctly via the Form (merging info with parent)
	const trackingInformation: { [event in FormTrackingEventTrigger]: ITrackingInformation } = {
		[FormTrackingEventTrigger.Submit]: {
			...(conversionId && { conversionId }),
		},
		[FormTrackingEventTrigger.SubmitSuccess]: {},
		[FormTrackingEventTrigger.TechnicalError]: {},
		[FormTrackingEventTrigger.ValidationError]: {},
	};

	trackingProps.trackingInformation = trackingInformation;

	// SelectionItem events should have component info of the parenting Salesforce Form
	if (trackingProps.trackedBlockInformation) {
		trackingProps.trackedBlockInformation.blockIsRelevant = false;
	}

	// Unset conversion since it is only relevant for submit events
	if (trackingProps.cmsTrackingInformation) {
		trackingProps.cmsTrackingInformation.conversionId = undefined;
	}

	return trackingProps;
};

export const SelectFormField: FC<ISelectFormFieldProps> = ({ field, translations, formPersonalizationHandler }) => {
	const { control } = useFormContext();

	// Register Tracking Ref in Parent context
	const registeredSelectionItems = useFormTrackingSelectionItems();
	const trackingRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		registeredSelectionItems.push({ trackingRef, id: field?.contentLink?.id?.toString() });

		return () => {
			const index = registeredSelectionItems.findIndex((item) => item?.id === field?.contentLink?.id?.toString());

			registeredSelectionItems.splice(index, 1);
		};
	}, []);

	if (!field?.contentLink?.expanded) {
		return;
	}

	const id = field.contentLink.id;
	const {
		displayMode,
		fieldLabel,
		fieldName,
		isMandatory,
		mandatoryErrorMessage,
		isHidden,
		selectionItems,
		valueSelectPrompt,
		makeReadOnlyIfHasInitialValue,
	} = field.contentLink.expanded;

	const FormElement = getFormElement(displayMode);

	return (
		<Tracked {...getTrackingProps(field?.contentLink.expanded)} trackingElementRef={trackingRef}>
			<Controller
				control={control}
				name={fieldName || String(id)}
				rules={{
					required: { value: Boolean(isMandatory), message: mandatoryErrorMessage ?? '' },
				}}
				render={({
					field: { onChange, onBlur, value, name, ref },
					formState: { isSubmitting, defaultValues },
					fieldState: { error },
				}) => {
					return (
						<FormElement
							ref={ref}
							label={fieldLabel}
							name={name}
							selectionItems={selectionItems ?? []}
							error={error}
							defaultValue={value}
							value={value}
							onBlur={onBlur}
							onChange={(e) => {
								const splitValue = (e.target.value || '').split(',');

								formPersonalizationHandler && formPersonalizationHandler(fieldName, splitValue);
								onChange(e);
							}}
							placeholder={valueSelectPrompt}
							isError={Boolean(error)}
							readOnly={Boolean(makeReadOnlyIfHasInitialValue) && Boolean(defaultValues?.[name])}
							hidden={Boolean(isHidden)}
							multiple={displayMode === SelectFormFieldDisplayMode.DROPDOWN_MULTI}
							required={isMandatory}
							translations={translations}
							trackingRef={trackingRef}
							disabled={isSubmitting}
						/>
					);
				}}
				key={field.contentLink.id}
			/>
		</Tracked>
	);
};
