import { IPageLanguage } from '@/interfaces/coreInformation';
import { getUpdatedLocale, splitLocale } from '@/utils/language';
import { useParams, useRouter, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

export interface ICultureInstance {
	language: string;
	country: string;
	changeCulture: (language: string | undefined, country?: string) => void;
	resetCulture: (culture?: string) => void;
	createCultureUrl: (language: string | undefined, country?: string) => string | undefined;
}

const formatLink = (fullUrl: string) => {
	// this util is made to be backwards compatible and "help" backend a bit
	// link propertry contains absolute URL's to CMS PREVIEW (not the page itself)
	// so they cannot be used as a redirect.
	// Pathname is correct tho and thats the only info we need
	// try catch is here if this is fixed and backend starts to deliver relative urls
	// in which case casting the new URL fails and the relative link is returned

	let url;

	try {
		// try parse
		url = new URL(fullUrl);
	} catch {
		console.error('Failed to parse FQDN: ', fullUrl);
		// most likely not absolute

		return fullUrl;
	}

	return url.pathname;
};

const updatePreferredLanguage = (preferredLanguage: string) => {
	return fetch('/api/VontobelProfile/SavePreferredLanguage', {
		method: 'POST',
		body: JSON.stringify({ PreferredLanguage: preferredLanguage }),
	});
};

export const useCulture = ({
	existingLanguages,
	savePreferredLanguage = false,
}: {
	existingLanguages: Array<IPageLanguage>;
	savePreferredLanguage?: boolean;
}): ICultureInstance => {
	const router = useRouter();
	const params = useParams();
	const query = useSearchParams().toString();

	const { language: currentLanguage, country: currentCountry } = splitLocale(params.lang as string);

	const createCultureUrl = useCallback((language: string | undefined, country?: string): string | undefined => {
		const { culture } = getUpdatedLocale(language, country);

		const newLanguage = existingLanguages.find((language) => language.name.toLowerCase() === culture.toLowerCase());

		if (!newLanguage) {
			return undefined;
		}

		return formatLink(newLanguage?.link);
	}, []);

	const changeCulture = useCallback(async (language: string | undefined, country?: string) => {
		const url = createCultureUrl(language, country);

		if (url) {
			const locale = url.split('/')[1];
			const { language: preferredLanguage } = splitLocale(locale);

			if (savePreferredLanguage) {
				await updatePreferredLanguage(preferredLanguage);
			}

			router.push(url + (query?.length > 0 ? `?${query}` : ''));
		}
	}, []);

	const resetCulture = async (culture?: string) => {
		if (savePreferredLanguage && culture) {
			const { language: preferredLanguage } = splitLocale(culture);

			await updatePreferredLanguage(preferredLanguage);
		}

		router.push('/' + culture || '');
	};

	return {
		language: currentLanguage,
		country: currentCountry,
		changeCulture,
		resetCulture,
		createCultureUrl,
	};
};
