'use client';

import { Button } from '@/components/core/Button/Button';
import { Checkbox } from '@/components/core/Checkbox';
import { SelectIcon } from '@/components/core/Select';
import { Tags } from '@/components/core/Tags/Tags';
import { Text } from '@/components/core/Text/Text';
import { css } from '@/styled-system/css';
import { Box, Flex, FlexProps, styled } from '@/styled-system/jsx';
import { dropdown } from '@/styles/theme/dropdown';
import { useOutsideClick } from '@/utils/handleOutsideClick';
import { useRef, useState } from 'react';

export interface IDropdownOption extends Record<string, any> {
	label: string;
	value: string;
	disabled?: boolean;
}

interface IDropdownProps extends Pick<FlexProps, 'id'> {
	options: Array<IDropdownOption>;
	placeholder: string;
	label?: string;
	helperText?: string;
	defaultSelected?: Array<IDropdownOption>;
	onChange?: (item: IDropdownOption | null) => void;
	disabled?: boolean;
	readOnly?: boolean;
	multiple?: boolean;
	scrolling?: boolean;
}

export const Dropdown = ({
	options,
	placeholder,
	label,
	helperText,
	defaultSelected,
	onChange,
	disabled = false,
	readOnly = false,
	multiple = false,
	scrolling = false,
	id,
}: IDropdownProps) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState<Array<IDropdownOption>>(defaultSelected ?? []);

	useOutsideClick(wrapperRef, () => setIsOpen(false));

	const handleOnChange = (item: IDropdownOption | null) => {
		if (!item) {
			return;
		}

		if (onChange) {
			onChange(item);
		}

		if (!multiple) {
			setSelectedOptions([item]);
		} else {
			const isOptionSelected = selectedOptions.some((option) => option.value === item.value);

			if (isOptionSelected) {
				setSelectedOptions(selectedOptions.filter((option) => option.value !== item.value));
			} else {
				setSelectedOptions([...selectedOptions, item]);
			}
		}

		if (!multiple) {
			return setIsOpen(false);
		}
	};

	const classes = dropdown.raw({ disabled, readOnly, multiple, scrolling });

	return (
		<Flex className={css(classes.root)}>
			{label && (
				<Text className={css(classes.label)} size="xs" fontWeight={600}>
					{label}
				</Text>
			)}
			<Box className={css(classes.select)} ref={wrapperRef}>
				{/* This id is used for focusing the element from the ArkUI dialog.
				We could use ref for this if we ever need the id to be somewhere else in the tree (perhaps on the Flex) but for now this is simpler */}
				<button
					id={id}
					className={css(classes.trigger)}
					onClick={() => setIsOpen(!isOpen)}
					data-open={isOpen}
					data-selected={selectedOptions.length > 0}
				>
					{selectedOptions.length ? (
						<>
							{multiple ? (
								<Tags.Root>
									{selectedOptions?.map((option, index) => {
										return (
											<Tags.Item key={`${option.value}-${index}`} className={css(classes.tag)}>
												<Tags.Label>{option?.label}</Tags.Label>
												<Tags.CloseButton
													as={Box}
													onClick={(e: MouseEvent) => {
														e.stopPropagation();

														handleOnChange(option);
													}}
												/>
											</Tags.Item>
										);
									})}
								</Tags.Root>
							) : (
								<Text size="sm">{selectedOptions[0].label}</Text>
							)}
						</>
					) : (
						<Text size="sm" noOfLines={1} lineHeight="100%">
							{placeholder}
						</Text>
					)}
					<SelectIcon isOpen={isOpen} className={css(classes.icon)} />
				</button>
				{isOpen && (
					<Box className={css(classes.positioner)} data-open={isOpen}>
						<styled.div className={css(classes.content)} id={label}>
							{options?.map((option) =>
								multiple ? (
									<Button
										rootProps={classes.option}
										variant="unstyled"
										onClick={() => handleOnChange(option)}
										key={option.value}
										disabled={option?.disabled}
										data-selected={selectedOptions?.some((selectedOption) => selectedOption.value === option.value)}
									>
										<Checkbox
											isChecked={selectedOptions?.some((selectedOption) => selectedOption.value === option.value)}
											label={option.label}
											disabled={option?.disabled}
										/>
									</Button>
								) : (
									<styled.div
										key={option.value}
										onClick={() => handleOnChange(option)}
										className={css(classes.option)}
										data-selected={selectedOptions?.some((selectedOption) => selectedOption.value === option.value)}
										data-disabled={option?.disabled}
										data-value={option.value}
										data-label={option.label}
									>
										{option.label}
									</styled.div>
								)
							)}
						</styled.div>
					</Box>
				)}
			</Box>

			{helperText && (
				<Text className={css(classes.helperText)} size="xxs">
					{helperText}
				</Text>
			)}
		</Flex>
	);
};
