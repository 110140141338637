import { SVGAttributes } from 'react';

export const ObserveIcon = (props: SVGAttributes<SVGElement>) => (
	<svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M16 9.20001C10.8 9.20001 6.39998 11.8 4.09998 16C6.29998 20.2 10.8 22.8 16 22.8C21.2 22.8 25.6 20.2 27.9 16C25.6 11.8 21.2 9.20001 16 9.20001ZM16 21.3C11.7 21.3 7.99998 19.3 5.89998 16C7.99998 12.7 11.7 10.7 16 10.7C20.3 10.7 24 12.7 26.1 16C24 19.3 20.3 21.3 16 21.3Z"
			fill="currentColor"
		></path>
		<path
			d="M16.9 19.7C18.9 19.2 20.2 17.2 19.7 15.1C19.2 13.1 17.2 11.8 15.1 12.3C13 12.8 11.8 14.8 12.3 16.9C12.8 18.9 14.9 20.2 16.9 19.7Z"
			fill="currentColor"
		></path>
	</svg>
);
