import { Heading } from '@/components/core/Heading/Heading';
import { ITextSnippetContentRendererProps } from '@/components/shared/TextSnippet/TextSnippet.types';
import { bloombergCodeToIso3166Alpha2Map } from '@/components/shared/TextSnippet/components/DailyTelegramContentRenderer/DailyTelegramContentRenderer.constants';
import parse from 'html-react-parser';

interface IDailyTelegramContentRendererProps extends ITextSnippetContentRendererProps {
	blockName?: string;
}

export const DailyTelegramContentRenderer = ({ children, content, blockName }: IDailyTelegramContentRendererProps) => {
	const finalContent = content.replace(/{(.{2})}/g, (_match, p1) => {
		const iso3166Alpha2 = bloombergCodeToIso3166Alpha2Map[p1.toUpperCase()];

		if (iso3166Alpha2 === undefined) {
			return `{p1}`;
		}

		return `<span data-testid="flag-${iso3166Alpha2}" class="fi fi-${iso3166Alpha2}"></span>`;
	});

	return children({
		children: (
			<>
				{blockName && (
					<Heading type="h2" as="h2" id={blockName.replaceAll(' ', '-').toLowerCase()}>
						{blockName}
					</Heading>
				)}
				{parse(finalContent)}
			</>
		),
	});
};
