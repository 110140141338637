import { FC, SVGAttributes } from 'react';

export const DownloadIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M22.205 16.455L20.795 15.045L17 18.835V6H15V18.835L11.205 15.045L9.795 16.455L16 22.665L22.205 16.455ZM4 24H28V26H4V24Z"
				fill="currentColor"
			/>
		</svg>
	);
};
