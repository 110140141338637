'use client';

import { PageContext } from '@/utils/pageContextProvider';
import { useContext, type SVGAttributes } from 'react';

export const MinusIcon = (props: SVGAttributes<SVGElement>) => {
	const { siteName } = useContext(PageContext);

	if (siteName === 'vosti') {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6.00073 15.749H26.0007V16.749H6.00073V15.749Z"
					fill="currentColor"
				/>
			</svg>
		);
	}

	return (
		<svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M17 15L15 15H5.5V17H15L17 17H26.5V15L17 15Z" fill="currentColor"></path>
		</svg>
	);
};
