import { IPreselectedValue } from '@/components/block-templates/Forms/FormContainerBlockTemplate/FormContainerBlockTemplate';
import { IFormSalesforceResponse } from '@/components/shared/Forms/FormSalesforce';
import { ContentType } from '@/enums/ContentType';
import { IVontobelProfile } from '@/interfaces/VontobelProfile';
import {
	FormFieldDisplayMode,
	IFormFieldContent,
	IFormFieldResponse,
	ISelectFormFieldContent,
	ISelectFormFieldResponse,
	ITextFormFieldContent,
	ITextFormFieldResponse,
	SelectFormFieldDisplayMode,
	TextFormFieldDisplayMode,
} from '@/interfaces/blocks/forms';
import { IBlockResponse } from '@/interfaces/coreInformation';
import { ReadonlyURLSearchParams } from 'next/navigation';

export function formatFormFieldData(formValues: Record<string, string>, formFields: Array<IFormFieldResponse>) {
	return formFields.reduce(
		(resultValues, formField) => {
			const formFieldName = formField?.contentLink?.expanded?.fieldName;
			const formFieldId = formField?.contentLink?.id;
			const selectionFields = (formField as ISelectFormFieldResponse)?.contentLink?.expanded?.selectionItems;

			if (selectionFields && selectionFields?.length > 0) {
				// This is a select field, find the selected options
				const selectedResult = selectionFields?.reduce((acc: { [id: string]: string }, selectionField) => {
					const optionValue = selectionField?.contentLink?.expanded?.value;
					const optionId = selectionField?.contentLink?.id;
					const isSelected = formValues[formFieldName || String(formFieldId)]?.includes(optionValue);

					acc[String(optionId)] = Boolean(isSelected)?.toString();

					return acc;
				}, {});

				resultValues[String(formFieldId)] = JSON.stringify(selectedResult);
			} else {
				// This is a text field, keep the value as-is
				resultValues[String(formFieldId)] = formValues[formFieldName || String(formFieldId)];
			}

			return resultValues;
		},
		{} as Record<string, string>
	);
}

export const extractSalesforceFormProps = (formSalesforce?: Array<IFormSalesforceResponse>) => {
	const salesforceForm = formSalesforce?.at(0)?.contentLink?.expanded;

	if (!salesforceForm) {
		return null;
	}

	return salesforceForm;
};

export const getDefaultValues = (
	preselectedValues?: Array<IPreselectedValue>,
	formFieldsAbove?: Array<IFormFieldResponse>,
	formFieldsBelow?: Array<IFormFieldResponse>,
	searchParams?: ReadonlyURLSearchParams,
	salesforceFormFields?: Array<IFormFieldResponse>,
	vontobelProfile?: IVontobelProfile
) => {
	const caseInsensitiveSearchParams = new URLSearchParams();

	for (const [name, value] of (searchParams || new URLSearchParams()).entries()) {
		caseInsensitiveSearchParams.append(name.toUpperCase(), value);
	}

	const allFormFields = [...(formFieldsAbove || []), ...(formFieldsBelow || []), ...(salesforceFormFields || [])];

	const textFieldefaultValues = getTextFieldsDefaultValues(
		preselectedValues,
		caseInsensitiveSearchParams,
		allFormFields
	);
	const selectFieldDefaultValues = getSelectFieldsDefaultValues(
		preselectedValues,
		caseInsensitiveSearchParams,
		allFormFields
	);

	const defaultValues = Object.assign(textFieldefaultValues, selectFieldDefaultValues);

	// react-hook-forms recommends to use defaultValues for the entire form.
	// avoid providing undefined as a default value, as it conflicts with the default state of a controlled component.
	const fieldsDefaultValues = getFieldsDefaultValues(defaultValues, allFormFields);

	Object.assign(defaultValues, fieldsDefaultValues);

	// Overwrite possible vontobelProfile values.
	const vontobelProfileDefaultValues = getDefaultValuesFromVontobelProfile(
		allFormFields,
		defaultValues,
		vontobelProfile
	);

	Object.assign(defaultValues, vontobelProfileDefaultValues);

	return defaultValues;
};

const getDefaultValuesFromVontobelProfile = (
	allFormFields: Array<IFormFieldResponse>,
	fieldsDefaultValues: { [fieldName: string]: string | undefined },
	vontobelProfile?: IVontobelProfile
): { [key: string]: string | undefined } => {
	const vontobelSalesforceProfileInfo = vontobelProfile?.salesforceProfileInfo;

	if (!vontobelProfile || !vontobelSalesforceProfileInfo) {
		return {};
	}

	const { subscriptions, hashId, ...vontobelProfileValues } = vontobelSalesforceProfileInfo;
	const defaultValues: { [key: string]: string | undefined } = {};

	for (const formField of allFormFields) {
		const fieldName = formField?.contentLink?.expanded?.fieldName;
		const overwriteWithDefaultOnLoad = formField?.contentLink?.expanded?.overwriteWithDefaultOnLoad;

		if (!fieldName) {
			continue;
		}

		if (overwriteWithDefaultOnLoad && fieldsDefaultValues[fieldName]) {
			defaultValues[fieldName] = fieldsDefaultValues[fieldName];
		} else {
			defaultValues[fieldName] = mergeFieldsDefaultValuesAndVontobelProfileValues(
				vontobelProfileValues,
				fieldsDefaultValues,
				fieldName
			);
		}
	}

	return defaultValues;
};

const filterForParamPrefilledFormFields = <T extends IFormFieldContent>(
	formFields: IBlockResponse<T>[],
	caseInsensitiveSearchParams?: URLSearchParams
): IBlockResponse<T>[] => {
	return formFields.filter((formField) => {
		const fieldName = formField?.contentLink?.expanded?.fieldName;
		const id = formField?.contentLink?.id;
		const preselectFromUrlParameters = formField?.contentLink?.expanded?.preselectFromUrlParameters;

		return preselectFromUrlParameters && caseInsensitiveSearchParams?.has((fieldName || String(id)).toUpperCase());
	});
};

const filterForPreSelectedFormFields = <T extends IFormFieldContent>(
	formFields: IBlockResponse<T>[],
	preselectedValues?: Array<IPreselectedValue>
): Array<IPreselectedValue> => {
	if (!preselectedValues) {
		return [];
	}

	return preselectedValues?.filter(({ key }) =>
		formFields
			?.map((formField) => {
				return formField?.contentLink?.expanded?.fieldName;
			})
			?.some((fieldName) => key === fieldName)
	);
};

const filterForPredefinedValueTextFormFields = (
	formFields: IBlockResponse<ITextFormFieldContent>[]
): IBlockResponse<ITextFormFieldContent>[] => {
	const isFieldPredefined = (formField: IBlockResponse<ITextFormFieldContent>): boolean | undefined =>
		!!formField?.contentLink?.expanded?.predefinedValue;

	return formFields.filter(isFieldPredefined);
};

const filterForPredefinedValueSelectFormFields = (
	formFields: IBlockResponse<ISelectFormFieldContent>[]
): IBlockResponse<ISelectFormFieldContent>[] => {
	const isFieldPredefined = (formField: IBlockResponse<ISelectFormFieldContent>): boolean | undefined =>
		formField?.contentLink?.expanded?.selectionItems?.some((si) => si?.contentLink?.expanded?.isInitiallyChecked);

	return formFields.filter(isFieldPredefined);
};

const getTextValueFromParam = (
	formField: ITextFormFieldResponse,
	caseInsensitiveSearchParams?: URLSearchParams
): { [fieldName: string]: string | undefined } => {
	const fieldName = formField?.contentLink?.expanded?.fieldName;
	const id = formField?.contentLink?.id;

	const value = caseInsensitiveSearchParams?.getAll((fieldName || String(id)).toUpperCase()).join(',');

	return { [fieldName ?? String(id)]: value };
};

const getTextPredefinedValue = (textFormField: ITextFormFieldResponse): { [fieldName: string]: string | undefined } => {
	const fieldName = textFormField?.contentLink?.expanded?.fieldName;
	const predefinedValue = textFormField?.contentLink?.expanded?.predefinedValue;
	const id = textFormField?.contentLink?.id;

	return { [fieldName ?? String(id)]: predefinedValue };
};

export function isTextFormField(formField: IFormFieldResponse) {
	return (
		!!formField?.contentLink?.expanded?.displayMode &&
		(Object.values(TextFormFieldDisplayMode) as FormFieldDisplayMode[]).includes(
			formField?.contentLink?.expanded?.displayMode
		)
	);
}

export function isSelectFormField(formField: IFormFieldResponse) {
	return (
		!!formField?.contentLink?.expanded?.displayMode &&
		(Object.values(SelectFormFieldDisplayMode) as FormFieldDisplayMode[]).includes(
			formField?.contentLink?.expanded?.displayMode
		)
	);
}

export function isFormUploadField(formField: IFormFieldResponse | undefined) {
	return (
		formField?.contentLink?.expanded?.contentType?.[formField?.contentLink?.expanded?.contentType?.length - 1] ===
		ContentType.FormFieldFileUploadBlock
	);
}

export function isTextBlockField(formField: IFormFieldResponse) {
	const contentType = formField?.contentLink?.expanded?.contentType;

	return contentType?.[contentType?.length - 1] === ContentType.TextBlock;
}

export function isHiddenFormField(formField: IFormFieldResponse) {
	return formField?.contentLink?.expanded?.contentType?.[1] === ContentType.FormFieldHiddenElementBlock;
}

export function isHiddenHoneyPotFormField(formField: IFormFieldResponse) {
	return formField?.contentLink?.expanded?.contentType?.[1] === ContentType.FormFieldHiddenHoneyPotBlock;
}

const getTextFieldsDefaultValues = (
	preselectedValues?: Array<IPreselectedValue>,
	caseInsensitiveSearchParams?: URLSearchParams,
	formFields?: Array<IFormFieldResponse>
): { [fieldName: string]: string | undefined } => {
	const textFields: Array<ITextFormFieldResponse> =
		formFields?.filter(isTextFormField).map((formField) => formField as ITextFormFieldResponse) ?? [];

	const defaultValuesFromParams = filterForParamPrefilledFormFields(textFields ?? [], caseInsensitiveSearchParams)
		.map((formField) => getTextValueFromParam(formField, caseInsensitiveSearchParams))
		.reduce((acc, curr) => ({ ...acc, ...curr }), {});

	const defaultValuesFromPreselectedValues = filterForPreSelectedFormFields(textFields, preselectedValues).reduce(
		(acc, curr) => ({ ...acc, [curr.key]: curr.value }),
		{}
	);

	const explicitlySetDefaultValue = filterForPredefinedValueTextFormFields(textFields ?? [])
		.map((formField) => getTextPredefinedValue(formField))
		.reduce((acc, curr) => ({ ...acc, ...curr }), {});

	return Object.assign(explicitlySetDefaultValue, defaultValuesFromPreselectedValues, defaultValuesFromParams);
};

const getSelectFieldsDefaultValues = (
	preselectedValues?: Array<IPreselectedValue>,
	caseInsensitiveSearchParams?: URLSearchParams,
	formFields?: Array<IFormFieldResponse>
): { [fieldName: string]: string | undefined } => {
	const selectFields: Array<ISelectFormFieldResponse> =
		formFields?.filter(isSelectFormField).map((formField) => formField as ISelectFormFieldResponse) ?? [];

	const defaultValuesFromParams = filterForParamPrefilledFormFields(selectFields, caseInsensitiveSearchParams)
		.map((formField) => getSelectedValueFormParam(formField, caseInsensitiveSearchParams))
		.reduce((acc, curr) => ({ ...acc, ...curr }), {});

	const defaultValuesFromPreselectedValues = filterForPreSelectedFormFields(selectFields, preselectedValues).reduce(
		(acc, curr) => ({ ...acc, [curr.key]: curr.value }),
		{}
	);

	const explicitlySetDefaultValue = filterForPredefinedValueSelectFormFields(selectFields)
		.map((formField) => getSelectPredefinedValue(formField))
		.reduce((acc, curr) => ({ ...acc, ...curr }), {});

	return Object.assign(explicitlySetDefaultValue, defaultValuesFromPreselectedValues, defaultValuesFromParams);
};

const getSelectedValueFormParam = (
	formField: ISelectFormFieldResponse,
	caseInsensitiveSearchParams?: URLSearchParams
): { [fieldName: string]: string | undefined } => {
	const fieldName = formField?.contentLink?.expanded?.fieldName;
	const selectionItems = formField?.contentLink?.expanded?.selectionItems;
	const id = formField?.contentLink?.id;

	const value = caseInsensitiveSearchParams
		?.getAll((fieldName || String(id)).toUpperCase())
		.filter((paramValue) =>
			selectionItems?.some((item) => {
				const preselectFromUrlParameters = item?.contentLink?.expanded?.preselectFromUrlParameters;
				const value = item?.contentLink?.expanded?.value;

				return (
					preselectFromUrlParameters &&
					(value.toUpperCase() === paramValue.toUpperCase() || paramValue.split(',').includes(value))
				);
			})
		)
		.map((item) => item.split(','))
		.flat()
		.filter((paramValue, i, arr) => arr.indexOf(paramValue) === i && Boolean(paramValue.length))
		.join(',');

	return { [fieldName || String(id)]: value };
};

const getSelectPredefinedValue = (selectFormField: ISelectFormFieldResponse) => {
	const fieldName = selectFormField?.contentLink?.expanded?.fieldName;
	const selectionItems = selectFormField?.contentLink?.expanded?.selectionItems;
	const id = selectFormField?.contentLink?.id;

	const value = selectionItems
		?.filter((item) => item?.contentLink?.expanded?.isInitiallyChecked)
		.map((item) => item?.contentLink?.expanded?.value)
		.join(',');

	return { [fieldName || String(id)]: value };
};

const getFieldsDefaultValues = (
	fieldsDefaultValues: { [fieldName: string]: string | undefined },
	formFields?: Array<IFormFieldResponse>
) => {
	return formFields
		?.map((formField) => {
			const fieldName = formField.contentLink?.expanded?.fieldName;

			if (fieldName) {
				return { [fieldName]: fieldsDefaultValues[fieldName] || '' };
			}
		})
		.reduce((acc, curr) => ({ ...acc, ...curr }), {});
};

const mergeFieldsDefaultValuesAndVontobelProfileValues = (
	vontobelProfileValues: { [key: string]: string | null },
	fieldsDefaultValues: { [fieldName: string]: string | undefined },
	fieldName: string
) => {
	const vontobelProfileFieldName = Object.keys(vontobelProfileValues).find(
		(vontobelProfileFieldName) => vontobelProfileFieldName.toLowerCase() === fieldName.toLowerCase()
	);

	if (vontobelProfileFieldName) {
		return vontobelProfileValues[vontobelProfileFieldName] || '';
	}

	return fieldsDefaultValues[fieldName] || '';
};

export const getHiddenHoneyPotFieldValue = (
	formValues: Record<string, string>,
	formFields: Array<IFormFieldResponse>
) => {
	const hiddenHoneyPotField = formFields.find(
		(field) => field?.contentLink?.expanded?.contentType?.at(-1) === ContentType.FormFieldHiddenHoneyPotBlock
	);

	if (hiddenHoneyPotField) {
		return formValues[hiddenHoneyPotField?.contentLink?.id];
	}

	return undefined;
};
