'use client';

import { Input } from '@/components/core/Input/Input';
import { TextArea } from '@/components/core/TextArea/TextArea';
import { TextFormFieldDisplayMode } from '@/interfaces/blocks/forms';
import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface ITextFormFieldProps {
	translations: { [key: string]: string };
	// eslint-disable-next-line @typescript-eslint/ban-types
	formPersonalizationHandler?: Function;

	displayMode?: TextFormFieldDisplayMode;
	fieldLabel: string | undefined;
	placeholder: string | undefined;
	helperText?: string;
	fieldName: string | undefined;
	textMaxLength?: number | undefined;
	isMandatory: boolean | undefined;
	mandatoryErrorMessage: string | undefined;
	isHidden: boolean | undefined;
	makeReadOnlyIfHasInitialValue: boolean | undefined;
	id: number;
	validationPattern?: RegExp;
	title?: string;
}

const getFormElement = (displayMode?: TextFormFieldDisplayMode) => {
	// TODO: Does date work for regular forms?
	switch (displayMode) {
		case TextFormFieldDisplayMode.LONGTEXT:
			return TextArea;
		case TextFormFieldDisplayMode.TEXT:
		case TextFormFieldDisplayMode.EMAIL:
		case TextFormFieldDisplayMode.NUMBER:
		case TextFormFieldDisplayMode.PHONE_NUMBER:
		case TextFormFieldDisplayMode.DATE:
			return Input;
		default:
			return Fragment;
	}
};

export const TextField: FC<ITextFormFieldProps> = ({
	translations,
	formPersonalizationHandler,
	displayMode,
	fieldLabel,
	placeholder,
	helperText,
	fieldName,
	textMaxLength,
	isMandatory,
	mandatoryErrorMessage,
	isHidden,
	makeReadOnlyIfHasInitialValue,
	validationPattern,
	title,
	id,
}) => {
	const { control, setValue, trigger } = useFormContext();

	const FormElement = getFormElement(displayMode);

	let inputPattern: RegExp | undefined = validationPattern;
	let translationKey = '';

	switch (displayMode) {
		case TextFormFieldDisplayMode.PHONE_NUMBER:
			inputPattern = /^\+[1-9]\d{1,16}$/;
			translationKey = 'forms.validation.phone-invalid.text';
			break;
		case TextFormFieldDisplayMode.EMAIL:
			inputPattern = /^\w+([-+.']*\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
			translationKey = 'forms.validation.email-invalid.text';
			break;
	}
	const validationErrorMessage: string = translationKey ? translations[translationKey] ?? translationKey : '';
	const name = fieldName || String(id);

	return (
		<Controller
			control={control}
			name={name}
			rules={{
				maxLength: displayMode !== TextFormFieldDisplayMode.NUMBER ? textMaxLength : undefined,
				required: { value: Boolean(isMandatory), message: mandatoryErrorMessage ?? '' },
				pattern: inputPattern && { value: inputPattern, message: validationErrorMessage ?? '' },
				...(textMaxLength &&
					displayMode === TextFormFieldDisplayMode.NUMBER && {
						max: {
							value: textMaxLength,
							message: translations['forms.validation.number-invalid.text'],
						},
					}),
			}}
			render={({
				field: { onChange, onBlur, value, ref },
				formState: { isSubmitting, defaultValues },
				fieldState: { error },
			}) => {
				return (
					<FormElement
						ref={ref}
						label={fieldLabel}
						displayMode={displayMode}
						isMandatory={isMandatory}
						placeholder={placeholder}
						helperText={helperText}
						error={error}
						value={value}
						onBlur={() => {
							trigger(name);

							formPersonalizationHandler && formPersonalizationHandler(name, value);
							onBlur && onBlur();
						}}
						title={title}
						onChange={(e) => {
							if (displayMode === TextFormFieldDisplayMode.NUMBER) {
								onChange(e);

								return;
							}

							if (!textMaxLength || e.target.value.length <= textMaxLength) {
								onChange(e);
							}
						}}
						readOnly={Boolean(makeReadOnlyIfHasInitialValue) && Boolean(defaultValues?.[name])}
						isError={Boolean(error)}
						hidden={Boolean(isHidden)}
						clearInput={() => setValue(fieldName || String(id), '')}
						translations={translations}
						disabled={isSubmitting}
					/>
				);
			}}
			key={id}
		/>
	);
};
