import { sva } from '@/styled-system/css';

export const inlineJobItemRecipe = sva({
	slots: ['container', 'date', 'label'],
	base: {
		container: {
			borderRadius: 'sm',
			borderWidth: '1px',
			borderStyle: 'solid',
			px: 6,
			py: { base: 2, xl: 4, lg: 4, md: 4 },
			mb: 4,
		},
		label: {
			flex: 1,
			display: 'flex',
			alignItems: 'center',
			py: 2,
		},
	},
	defaultVariants: {
		backgroundVariant: 'white',
	},
	variants: {
		backgroundVariant: {
			white: {
				container: {
					color: 'text.regular.default',
					backgroundColor: 'surface.interactive.card.pri',
					borderColor: 'other.border.regular.default',
					_hover: {
						backgroundColor: 'surface.interactive.transparent.regular.pressed',
						borderColor: 'transparent',
					},
				},
				date: { color: 'text.regular.subtitle' },
			},
		},
	},
});
