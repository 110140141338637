import { Button } from '@/components/core/Button/Button';
import { Text } from '@/components/core/Text/Text';
import { mapFormFileUploadErrorToTranslationKey } from '@/components/shared/Forms/FileUploadFormField/FileUploadFormField.utils';
import { FileUploadError } from '@/components/shared/Forms/FileUploadFormField/components/FileUpload';
import { fileUploadItem } from '@/components/shared/Forms/FileUploadFormField/components/FileUpload.recipe';
import { getIconByMimeType } from '@/const/icon';
import { MimeType } from '@/enums/MimeType';
import { AlertIcon } from '@/icons/AlertIcon';
import { CloseIcon } from '@/icons/CloseIcon';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';
import { FC } from 'react';

interface IRejectedFileUploadItemProps {
	file: File;
	index: number;
	onRemoveItem: (index: number) => void;
	translations: TranslationLabelValues;
	errors: string[];
}

export const RejectedFileUploadItem: FC<IRejectedFileUploadItemProps> = ({
	errors,
	file,
	index,
	onRemoveItem,
	translations,
}) => {
	const mimeIcon = getIconByMimeType(file.type as MimeType);

	const rejectedItemsClasses = fileUploadItem({ state: 'rejected' });

	return (
		<div className={rejectedItemsClasses.uploadedItemWrapper} key={index}>
			<Button
				type="button"
				title={translate(translations, 'forms.help.fileupload-remove-button-title')}
				leftIcon={<CloseIcon className={rejectedItemsClasses.actionIcon} />}
				variant="unstyled"
				size="medium"
				className="peer"
				onClick={() => onRemoveItem(index)}
			/>
			<div className={rejectedItemsClasses.uploadedItemContent}>
				<span>{mimeIcon}</span>
				<div className={rejectedItemsClasses.uploadedItemTextContent}>
					<Text color="text.regular.danger" size="sm" lineClamp={1} overflowWrap="anywhere">
						{file.name}
					</Text>
					<Text color="text.regular.danger" size="xs" lineClamp={1} overflowWrap="anywhere">
						{(file.size / (1024 * 1024)).toFixed(1)} MB&nbsp;&nbsp;
						<Text as="span" size="sm">
							|
						</Text>
						&nbsp;&nbsp;
						{Boolean(errors && errors?.length) && (
							<>
								{errors.map((error, index) => (
									<Text as="span" color="text.regular.danger" size="sm">
										{translate(translations, mapFormFileUploadErrorToTranslationKey(error as FileUploadError))}
										{index < errors.length - 1 ? ',' : ''}
									</Text>
								))}
							</>
						)}
					</Text>
				</div>
				<span>
					<AlertIcon className={rejectedItemsClasses.stateIcon} />
				</span>
			</div>
		</div>
	);
};
