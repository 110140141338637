'use client';

import { ITrackedContainerProps } from '@/components/shared/TrackedContainer/TrackedContainer';
import { ITrackedContextValue, TrackedContext } from '@/components/shared/tracking/Tracked.context';
import {
	EventAttachMode,
	IAttachedDOMTrackingEventInfo,
	ITrackedBlockInformation,
	ITrackingEventData,
	ITrackingInformation,
} from '@/types/tracking';
import { logger } from '@/utils/logger';
import { doTrack, mergeTrackingInformation, sendTealiumTrackingEvent } from '@/utils/tracking/tracking';
import React, { FC, RefObject, useContext, useEffect } from 'react';

export interface ITrackedProps extends ITrackedContainerProps {
	/**
	 * The Ref to the HTML element to which the tracking event handler should be added.
	 *
	 * The ref is also used to send the Tealium event, so usually it should be the same DOM element where the original event was triggered but this is not a necessity.
	 *
	 * The ref should ALWAYS be the html element that triggered the event or a parent of that element. Otherwise no tracking event will be sent.
	 */
	trackingElementRef?: RefObject<HTMLElement>;
	/**
	 * The tracking is used to identify tracking objects from server to client side.
	 *
	 * On the HTML element that should be tracked, set data-trackingid to this value. This provides the option to also track server side elements.
	 */
	trackingId?: string;

	/**
	 * Whether Tracking Events should be automatically applied to the DOM events set in trackingInformation.
	 */
	attachTrackingEvent?: EventAttachMode;

	trackingSelector?: string;
}

export const Tracked: FC<ITrackedProps> = ({
	trackingInformation,
	cmsTrackingInformation,
	trackedBlockInformation,
	children,
	href,
	trackingUseCase,
	trackingTarget,
	trackingElementRef,
	attachTrackingEvent = EventAttachMode.Auto,
	trackingId,
	trackingSelector,
}) => {
	useEffect(() => {
		if (trackingSelector) {
			bindTrackingViaSelector();
		}

		if (trackingId) {
			bindTrackingViaTrackingId();
		}
	}, []);

	useEffect(() => {
		// TODO: take care of double binding
		// Perhaps this can be only on mount but then add an await in the tests
		bindTrackingViaRef();
	}, [trackingElementRef?.current]);

	if (!trackingId && !trackingElementRef && !trackingSelector) {
		console.error(
			'In order for events to be attached, either trackingId, trackingElementRef or trackingSelector has to be provided. If no events should be attached, please use TrackedContainer instead.'
		);

		return;
	}
	const parentContext: ITrackedContextValue = useContext(TrackedContext);

	const collectTrackingData = (eventName: string): ITrackingEventData => {
		const parentData: ITrackingEventData = parentContext.collectTrackingData(eventName);

		const levels = parentData.levels;

		const hasRelevantTrackingParent = levels.some((level) => level.blockIsRelevant);

		if (trackedBlockInformation) {
			levels.push(trackedBlockInformation);
		}

		const component: ITrackedBlockInformation | undefined = trackedBlockInformation?.blockIsRelevant
			? trackedBlockInformation
			: parentData.component;

		if (component && trackedBlockInformation?.blockIsRelevant) {
			component.blockId = trackedBlockInformation.blockInstanceId;
			component.blockName = trackedBlockInformation.blockInstanceName;
		}

		if (component && hasRelevantTrackingParent) {
			component.blockInstanceId = trackedBlockInformation?.blockInstanceId || '';
			component.blockInstanceName = trackedBlockInformation?.blockInstanceName || '';
		}

		let trackingInfo: ITrackingInformation = {};

		// Only merge href when event is clickEvent.
		if (eventName == 'click') {
			trackingInfo = mergeTrackingInformation(
				cmsTrackingInformation,
				trackingInformation ? trackingInformation[eventName] : undefined,
				trackingUseCase,
				href,
				trackingTarget,
				trackedBlockInformation
			);
		} else {
			trackingInfo = mergeTrackingInformation(
				cmsTrackingInformation,
				trackingInformation ? trackingInformation[eventName] : undefined,
				trackingUseCase,
				undefined,
				trackingTarget,
				trackedBlockInformation
			);
		}

		trackingInfo = mergeTrackingInformation(trackingInfo, parentData.event);

		return {
			component,
			levels,
			nestingLevel: (parentData.nestingLevel ?? -1) + 1,
			event: trackingInfo,
		};
	};

	const bindTracking = (trackingElement: HTMLElement | null) => {
		if (!trackingElement) {
			return;
		}

		// Attach DOM events to ref
		if (trackingElement.dataset.tracked == 'true') {
			return;
		}

		trackingElement.dataset.tracked = 'true';

		const eventNames: string[] = trackingInformation ? Object.keys(trackingInformation) : ['click'];

		for (const eventName of eventNames) {
			// Note: Tracking handlers are created on component mount and contain the initial collectTrackingData function.
			// If trackingInformation is changed in that function on Tracked rerender, the changes are not reflected in the tracking handler.
			// This is the case for VimeoPlayer, where the trackingInformation label is changed after the video metadata is loaded.
			trackingElement.addEventListener('tracking-on' + eventName, (event: Event) => {
				const customEvent = event as CustomEvent<IAttachedDOMTrackingEventInfo>;

				doTrackInformation(
					eventName,
					customEvent.detail.originalEvent,
					customEvent.detail.customTrackingInformation,
					customEvent.detail.skipMerge,
					trackingElement
				);
			});

			if (attachTrackingEvent === EventAttachMode.Auto) {
				trackingElement.addEventListener(eventName, (event: Event) => {
					if (event.target) {
						doTrack(event as Event & { target: HTMLElement });
					}
				});
			}
		}
	};

	const bindTrackingViaSelector = () => {
		if (!trackingSelector) {
			return;
		}

		const trackingElementsHTMLCollection = document.querySelectorAll(trackingSelector);
		const trackingElements = Array.from(trackingElementsHTMLCollection) as HTMLElement[];

		for (const trackingElement of trackingElements) {
			bindTracking(trackingElement);
		}
	};

	const bindTrackingViaRef = () => {
		if (!trackingElementRef) {
			return;
		}

		if (trackingElementRef?.current) {
			bindTracking(trackingElementRef.current);

			return;
		}

		// Tries to attach events after the Tracked component has been rendered initially.
		// Initially needed for the wealth check component, which purposely delays the first render
		// Maybe this is needed for other components as well so adding the LOG
		setTimeout(() => {
			if (trackingElementRef?.current) {
				bindTracking(trackingElementRef.current);
			} else {
				console.info(
					`Attachment of tracking events failed, since no tracking event was found.\n` +
						`Tracking Ref (for server components): ${trackingElementRef}`
				);
			}
		}, 2000);
	};

	const bindTrackingViaTrackingId = () => {
		let trackingElement = document.querySelector(`[data-trackingid="${trackingId}"]`) as HTMLElement;

		if (trackingElement) {
			bindTracking(trackingElement);

			return;
		}

		// Tries to attach events after the Tracked component has been rendered initially.
		// Initially needed for the wealth check component, which purposely delays the first render
		// Maybe this is needed for other components as well so adding the LOG
		setTimeout(() => {
			trackingElement = document.querySelector(`[data-trackingid="${trackingId}"]`) as HTMLElement;

			if (trackingElement) {
				bindTracking(trackingElement);
			} else {
				console.info(
					`Attachment of tracking events failed, since no tracking event was found.\n` +
						`Tracking ID (for server components): ${trackingId}`
				);
			}
		}, 2000);
	};

	const doTrackInformation = (
		eventType: string,
		originalEvent: Event | undefined,
		trackingInformation: ITrackingInformation,
		skipMerge = false,
		trackingElement?: HTMLElement
	): void => {
		const eventData = collectTrackingData(eventType);

		if (skipMerge) {
			eventData.event = trackingInformation;
		} else {
			eventData.event = mergeTrackingInformation(trackingInformation, eventData.event);
		}

		logger.debug(JSON.stringify(eventData));

		sendTealiumTrackingEvent(eventData, trackingElement, originalEvent);
	};

	const myTrackedContextValue: ITrackedContextValue = {
		collectTrackingData,
	};

	return <TrackedContext.Provider value={myTrackedContextValue}>{children}</TrackedContext.Provider>;
};
