export const bloombergCodeToIso3166Alpha2Map: Record<string, string | undefined> = {
	AF: 'af',
	AL: 'al',
	AI: 'dz',
	AN: 'ad',
	AO: 'ao',
	AG: 'ag',
	AR: 'ar',
	AE: 'am',
	AB: 'aw',
	AU: 'au',
	AS: 'at',
	AZ: 'az',
	BH: 'bs',
	BJ: 'bh',
	BW: 'bd',
	BR: 'bb',
	BK: 'by',
	BE: 'be',
	BI: 'bz',
	BN: 'bj',
	BD: 'bm',
	BT: 'bt',
	BL: 'bo',
	BY: 'ba',
	BO: 'bw',
	BZ: 'br',
	BM: 'bn',
	BP: 'bg',
	BQ: 'bf',
	BG: 'bi',
	CF: 'cf',
	KH: 'kh',
	CM: 'cm',
	CA: 'ca',
	CV: 'cv',
	E3: '',
	CI: 'ky',
	CD: 'td',
	CL: 'cl',
	CH: 'cn',
	CO: 'co',
	CN: 'km',
	CK: 'cg',
	OI: 'ck',
	CR: 'cr',
	HR: 'hr',
	CU: 'cu',
	CT: 'cw',
	CC: 'cy',
	CZ: 'cz',
	ZA: 'cd',
	DE: 'dk',
	DJ: 'dj',
	DM: 'dm',
	DR: 'do',
	ED: 'ec',
	EG: 'eg',
	EL: 'sv',
	EN: 'gb-eng',
	EQ: 'gq',
	ER: 'er',
	EE: 'ee',
	ET: 'et',
	EU: 'eu',
	FK: 'fk',
	FS: 'fo',
	FJ: 'fj',
	FI: 'fi',
	FR: 'fr',
	FP: 'pf',
	GO: 'ga',
	GA: 'gm',
	GG: 'ge',
	GE: 'de',
	GH: 'gh',
	GI: 'gi',
	GR: 'gr',
	GL: 'gl',
	GD: 'gd',
	GP: 'gp',
	GM: 'gu',
	GU: 'gt',
	GS: 'gg',
	GN: 'gn',
	GW: 'gw',
	GY: 'gy',
	HT: 'ht',
	HN: 'hn',
	HK: 'hk',
	HU: 'hu',
	IC: 'is',
	IN: 'in',
	ID: 'id',
	IA: 'ir',
	IQ: 'iq',
	IR: 'ie',
	IO: 'im',
	IS: 'il',
	IT: 'it',
	IV: 'ci',
	JM: 'jm',
	JN: 'jp',
	JE: 'je',
	JO: 'jo',
	KZ: 'kz',
	KN: 'ke',
	KB: 'ki',
	KV: 'xk',
	'4Z': '',
	KU: 'kw',
	KY: 'kg',
	LO: 'la',
	LL: 'lv',
	LE: 'lb',
	LH: 'ls',
	LR: 'lr',
	LY: 'ly',
	LC: 'li',
	LN: 'lt',
	LX: 'lu',
	U2: 'mo',
	MC: 'mk',
	MG: 'mg',
	MW: 'mw',
	MA: 'my',
	MV: 'mv',
	MH: 'ml',
	MB: 'mt',
	MR: 'mh',
	MM: 'mr',
	MP: 'mu',
	MX: 'mx',
	MF: 'fm',
	MK: 'md',
	MQ: 'mc',
	MJ: 'mn',
	';7': 'me',
	MO: 'ma',
	MZ: 'mz',
	MY: 'mm',
	';3': 'gb-nir',
	NA: 'na',
	NU: 'nr',
	NP: 'np',
	NV: '',
	NE: 'nl',
	NZ: 'nz',
	NC: 'ni',
	NR: 'ne',
	NG: 'ng',
	NK: 'kp',
	NO: 'no',
	OM: 'om',
	PP: 'pg',
	PK: 'pk',
	PA: 'ps',
	PN: 'pa',
	PG: 'py',
	PE: 'pe',
	PH: 'ph',
	PD: 'pl',
	PO: 'pt',
	PR: 'pr',
	QA: 'qa',
	RO: 'ro',
	RU: 'ru',
	RW: 'rw',
	TM: 'st',
	WS: 'ws',
	SM: 'sm',
	SR: 'sa',
	SC: 'gb-sct',
	SB: 'sn',
	RR: 'rs',
	SS: 'sc',
	S1: 'sl',
	SI: 'sg',
	SO: 'sk',
	SV: 'si',
	SJ: 'sb',
	SQ: 'so',
	SA: 'za',
	SK: 'kr',
	'-L': 'ss',
	SP: 'es',
	SL: 'lk',
	VT: 'vc',
	KI: 'kn',
	LD: 'lc',
	SD: 'sd',
	SU: 'sr',
	S2: 'sz',
	SW: 'se',
	SZ: 'ch',
	SY: 'sy',
	TA: 'tw',
	TJ: 'tj',
	TZ: 'tz',
	TH: 'th',
	TL: 'tl',
	TG: 'tg',
	TO: 'to',
	TR: 'tt',
	TN: 'tn',
	TU: 'tr',
	TT: 'tm',
	TC: 'tc',
	TV: 'tv',
	UA: 'ae',
	UG: 'ug',
	UK: 'ua',
	GB: 'gb',
	UN: 'un',
	US: 'us',
	UR: 'uy',
	UZ: 'uz',
	VA: 'vu',
	VC: '',
	VZ: 've',
	VN: 'vn',
	VS: 'vg',
	VG: 'vi',
	WA: 'gb-wls',
	YE: 'ye',
	ZM: 'zm',
	ZI: 'zw',
};
