'use client';

import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';

export interface ILoginNavigationContext {
	isLoginOpen: boolean;
	setIsLoginOpen: Dispatch<SetStateAction<boolean>>;
}

export const LoginNavigationContext = createContext<ILoginNavigationContext | undefined>(undefined);

export const useLoginNavigationContext = (): ILoginNavigationContext => {
	const context = useContext(LoginNavigationContext);

	if (!context) {
		throw new Error('useLoginNavigationContext must be used within a LoginNavigationProvider');
	}

	return context;
};

export const LoginNavigationProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [isLoginOpen, setIsLoginOpen] = useState(false);

	return (
		<LoginNavigationContext.Provider value={{ isLoginOpen, setIsLoginOpen }}>
			{children}
		</LoginNavigationContext.Provider>
	);
};
