import { SVGAttributes } from 'react';

export const FacebookIcon = (props: SVGAttributes<SVGElement>) => (
	<svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M13.8373 26.5H17.6813L17.6232 16.8938H20.8277L21.3073 13.1661H17.6232V10.79C17.6232 9.71453 17.9211 8.98062 19.4689 8.98062H21.4381V5.64533C21.0966 5.60173 19.9267 5.5 18.5678 5.5C15.7266 5.5 13.7792 7.23668 13.7792 10.4194V13.1661H10.5601V16.8938H13.7792L13.8373 26.5Z"
			fill="currentColor"
		></path>
	</svg>
);
