'use client';
import { FC, useContext } from 'react';

import { ILinkListBlockTemplateProps } from '@/components/block-templates/LinkListBlockTemplate/LinkListBlockTemplate';
import { Button } from '@/components/core/Button/Button';
import { Heading } from '@/components/core/Heading/Heading';
import { ContentContainer } from '@/components/shared/ContentContainer/ContentContainer';
import { LoginEvolution } from '@/components/shared/LoginNavigation/LoginEvolution';
import { LoginNavigationContentDesktop } from '@/components/shared/LoginNavigation/LoginNavigationContent.desktop';
import { LoginNavigationContentMobile } from '@/components/shared/LoginNavigation/LoginNavigationContent.mobile';
import { LoginIcon } from '@/icons/LoginIcon';
import { ITranslatableComponentProps } from '@/interfaces/ITranslatableComponentProps';
import { IBlockResponse } from '@/interfaces/coreInformation';
import { VStack } from '@/styled-system/jsx';
import { navigation, scrollbar } from '@/styled-system/recipes';
import { translate } from '@/utils/i18n/translation-labels/translationLabels';
import { DialogBackdrop, DialogContent, DialogPositioner, DialogRoot } from '@ark-ui/react/dialog';
import { Portal } from '@zag-js/react';
import { HeaderContext } from '@/components/shared/Header/components/HeaderProvider/HeaderProvider';
import { useLoginNavigationContext } from '@/components/shared/LoginNavigation/LoginNavigationProvider';

interface IDialogProps extends ITranslatableComponentProps {
	quickAccessMenu?: IBlockResponse<ILinkListBlockTemplateProps>[];
	displayEvolutionSystem?: boolean;
}

export const LoginNavigation: FC<IDialogProps> = ({
	quickAccessMenu,
	displayEvolutionSystem = false,
	translations,
}) => {
	const classes = navigation();
	const { isLoginOpen, setIsLoginOpen } = useLoginNavigationContext();
	const { headerRef } = useContext(HeaderContext);
	const headerHeight = headerRef?.current?.clientHeight || 0;

	if (!quickAccessMenu?.length) {
		return null;
	}

	return (
		<>
			<Button
				variant="secondary"
				size="small"
				onClick={() => setIsLoginOpen(true)}
				leftIcon={<LoginIcon />}
				_print={{ display: 'none' }}
				{...(isLoginOpen ? { 'data-active': true } : {})}
			>
				{translate(translations, 'header.logins.button')}
			</Button>

			<DialogRoot open={isLoginOpen} onOpenChange={(e) => setIsLoginOpen(e.open)} lazyMount unmountOnExit>
				<Portal>
					<DialogBackdrop className={classes.backdrop} style={{ top: `${headerHeight}px` }} />
					<DialogPositioner
						className={classes.positioner}
						style={{ top: `${headerHeight}px`, height: isLoginOpen ? `calc(100% - ${headerHeight}px)` : 'min-content' }}
						data-state={isLoginOpen ? 'open' : 'closed'}
					>
						<ContentContainer overflowY="auto" className={scrollbar({ variant: 'hidden' })}>
							<DialogContent className={classes.content}>
								<VStack alignItems="flex-left" h="full">
									<Heading
										as="p"
										type="h1"
										p={{ base: '24px', sm: '24px 0px', md: '24px 0px 48px 0px', lg: '64px 0px 72px 0px' }}
									>
										{translate(translations, 'header.logins.title')}
									</Heading>
									<LoginNavigationContentDesktop data={quickAccessMenu} display={{ base: 'none', md: 'flex' }} />
									<LoginNavigationContentMobile data={quickAccessMenu} display={{ base: 'flex', md: 'none' }} />
								</VStack>
							</DialogContent>
						</ContentContainer>
						{displayEvolutionSystem && <LoginEvolution />}
					</DialogPositioner>
				</Portal>
			</DialogRoot>
		</>
	);
};
