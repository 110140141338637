'use client';

import { ButtonLink } from '@/components/core/ButtonLink/ButtonLink';
import { previewModeBannerRecipe } from '@/components/shared/PreviewModeBanner/PreviewModeBanner.recipe';
import { ExternalLinkIcon } from '@/icons/ExternalLinkIcon';
import { ObserveIcon } from '@/icons/ObserveIcon';
import { HTMLStyledProps, styled } from '@/styled-system/jsx';
import { usePathname, useSearchParams } from 'next/navigation';
import { FC } from 'react';

export const PreviewModeBanner: FC<HTMLStyledProps<'div'>> = (props) => {
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const epimode = searchParams.get('epimode');

	if (!epimode || epimode !== 'preview') {
		return null;
	}

	const classes = previewModeBannerRecipe();

	const queryParams = new URLSearchParams(searchParams.toString());

	queryParams.delete('epimode');
	const queryParamsString = queryParams.toString();

	const publicVersionUrl = queryParamsString ? `${pathname}?${queryParamsString}` : pathname;

	return (
		<styled.div className={classes.container} {...props}>
			<styled.div className={classes.contentWrapper}>
				<styled.div className={classes.iconTextContainer}>
					<styled.span>
						<ObserveIcon width="40px" height="40px" />
					</styled.span>

					<styled.div>
						<styled.h6 className={classes.title}>
							Preview with UNPUBLISHED changes only visible internally (Vontobel network)
						</styled.h6>
						<styled.p className={classes.description}>
							The link to this page shows an internal preview version of the page. DO NOT SHARE THIS LINK!
						</styled.p>
					</styled.div>
				</styled.div>

				<ButtonLink
					href={publicVersionUrl}
					target="_blank"
					variant="secondary"
					size="medium"
					rightIcon={<ExternalLinkIcon />}
					rootProps={{ borderColor: 'text.regular.default', alignSelf: 'center' }}
				>
					View public version
				</ButtonLink>
			</styled.div>
		</styled.div>
	);
};
