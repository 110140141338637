import { SVGAttributes } from 'react';

export const TrashIcon = (props: SVGAttributes<SVGElement>) => (
	<svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M24.3302 8.27931H19.6064V5.9428C19.6064 5.58724 19.3016 5.23169 18.946 5.23169H13.1048C12.7492 5.23169 12.3937 5.53645 12.3937 5.9428V8.27931H7.66985C7.3143 8.27931 6.95874 8.58407 6.95874 8.99042V10.7174C6.95874 11.0222 7.16191 11.2761 7.46668 11.3777L9.6508 25.4984C9.6508 25.8539 10.0064 26.1079 10.3111 26.1079H21.6381C21.9937 26.1079 22.2476 25.8539 22.2984 25.4984L24.4825 11.3777C24.7873 11.2761 24.9905 11.0222 24.9905 10.7174V8.99042C24.9905 8.63486 24.6857 8.27931 24.2794 8.27931H24.3302ZM16 6.7555H18.0825V8.27931H13.9175V6.7555H16ZM20.9778 24.5333H11.073L9.09207 11.3269H22.9587L20.9778 24.5333Z"
			fill="currentColor"
		></path>
	</svg>
);
