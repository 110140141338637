'use client';

import { PageContext } from '@/utils/pageContextProvider';
import { useContext, type SVGAttributes } from 'react';

export const PlusIcon = (props: SVGAttributes<SVGElement>) => {
	const { siteName } = useContext(PageContext);

	if (siteName === 'vosti') {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M15.5395 15.7489V6.1748H16.5395V15.7489H26.0007V16.7489H16.5395V26.1748H15.5395V16.7489H6.00073V15.7489H15.5395Z"
					fill="currentColor"
				/>
			</svg>
		);
	}

	return (
		<svg fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M17 5.5H15V15H5.5V17H15V26.5H17V17H26.5V15H17V5.5Z" fill="currentColor"></path>
		</svg>
	);
};
