import { FC, SVGAttributes } from 'react';

export const ExternalLinkIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M22.7036 8.00004L24 7.98962V13.1193H22.7036L22.7036 10.1412L15.9793 16.8653L15.1676 16.0536L21.8811 9.34022H18.9137V8.00004H22.7036Z"
				fill="currentColor"
			/>
			<path
				d="M15.4924 8.03503H8.00879V24H23.9738L23.9738 17.0153H22.6667V22.6667H9.33333V16V9.33334H15.4924V8.03503Z"
				fill="currentColor"
			/>
		</svg>
	);
};
