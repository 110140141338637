import { TextSnippetTracked } from '@/components/shared/TextSnippet/components/TextSnippetTracked/TextSnippetTracked';
import { IPageData } from '@/interfaces/coreInformation';
import React from 'react';

interface ITextSnippetTrackingWrapperProps {
	dataId: string;
	pageData?: IPageData;
	preventAutomatedTracking?: boolean;
	children: React.ReactNode;
}

export const TextSnippetTrackingWrapper = ({
	children,
	dataId,
	pageData,
	preventAutomatedTracking,
}: ITextSnippetTrackingWrapperProps) => {
	if (preventAutomatedTracking || !pageData) {
		return <>{children}</>;
	}

	return (
		<TextSnippetTracked textBlockId={dataId} pageData={pageData}>
			{children}
		</TextSnippetTracked>
	);
};
