import { sva } from '@/styled-system/css';

export const previewModeBannerRecipe = sva({
	slots: ['container', 'contentWrapper', 'iconTextContainer', 'title', 'description'],
	base: {
		container: {
			display: 'flex',
			justifyContent: 'center',
			backgroundColor: 'evolution.citrine.40',
			py: 6,
			px: { base: 6, md: 8 },
		},
		contentWrapper: {
			maxW: 'var(--content-max-width)',
			width: 'full',
			display: 'flex',
			justifyContent: 'space-between',
		},
		iconTextContainer: {
			flexGrow: 1,
			display: 'flex',
			alignItems: 'center',
			gap: 4,
		},
		title: {
			fontSize: '16px',
			fontWeight: '700',
		},
		description: {
			fontSize: '12px',
			fontWeight: '500',
		},
	},
});
