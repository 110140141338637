import { IDropdownOption } from '@/components/core/Dropdown/Dropdown';
import { Tags } from '@/components/core/Tags/Tags';
import useQueryParams from '@/hooks/useQueryParams';
import { omit } from 'lodash';

interface IJobFilterTagsProps {
	filters: {
		locations: Array<IDropdownOption>;
		departments: Array<IDropdownOption>;
	};
}

export const JobFilterTags = ({ filters }: IJobFilterTagsProps) => {
	const { query, change } = useQueryParams();

	if (!query || Object.values(query).length === 0) {
		return <Tags.Root />;
	}

	const data = (() => {
		const mergedQueryValues = [...(query?.departments || []), ...(query?.locations || [])];

		const selectData = [...filters?.departments, ...filters?.locations];

		return selectData?.filter((option) => mergedQueryValues?.includes(option.value));
	})();

	const removeItemFromQuery = (options: IDropdownOption | string) => {
		if (typeof options === 'string') {
			const search = options;
			const newQuery = omit(query, [search]);

			change(newQuery);

			return;
		}

		const newFilterQuery = Object.keys(query).reduce((acc, key) => {
			if (query[key].includes(options.value)) {
				const newQuery = (query[key] as Array<string>).filter((item) => item !== options.value);

				if (newQuery.length === 0) {
					return acc;
				}

				return { ...acc, [key]: newQuery };
			}

			return {
				...acc,
				[key]: query[key],
			};
		}, {});

		change(newFilterQuery);
	};

	return (
		<Tags.Root>
			{data?.map((option) => {
				return (
					<Tags.Item key={option.value}>
						<Tags.Label>{option.label}</Tags.Label>
						<Tags.CloseButton
							onClick={(e: MouseEvent) => {
								e.stopPropagation();
								removeItemFromQuery(option);
							}}
						/>
					</Tags.Item>
				);
			})}
		</Tags.Root>
	);
};
