'use client';

import { FC, useEffect } from 'react';

export interface ITealiumUniversalDataProviderProps {
	pageTrackingData: Record<string, unknown>;
}

export const TealiumUniversalDataProvider: FC<ITealiumUniversalDataProviderProps> = ({ pageTrackingData }) => {
	useEffect(() => {
		if (typeof window === 'undefined') {
			return;
		}

		window.utag_data = pageTrackingData;
	}, [JSON.stringify(pageTrackingData)]);

	return null;
};
