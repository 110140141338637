import { Icon } from '@/enums/Icon';
import { MimeType } from '@/enums/MimeType';
import { WiFiIcon } from '@/icons/WiFiIcon';
import dynamic from 'next/dynamic';

const CalculatorIcon = dynamic(() => import('@/icons/CalculatorIcon').then((mod) => mod.CalculatorIcon));
const AlertIcon = dynamic(() => import('@/icons/AlertIcon').then((mod) => mod.AlertIcon));
const AlphaPyramideIcon = dynamic(() => import('@/icons/AlphaPyramideIcon').then((mod) => mod.AlphaPyramideIcon));
const AssetAllocationIcon = dynamic(() => import('@/icons/AssetAllocationIcon').then((mod) => mod.AssetAllocationIcon));
const BookmarkIcon = dynamic(() => import('@/icons/BookmarkIcon').then((mod) => mod.BookmarkIcon));
const CalendarIcon = dynamic(() => import('@/icons/CalendarIcon').then((mod) => mod.CalendarIcon));
const ClarityInnovationIcon = dynamic(() =>
	import('@/icons/ClarityInnovationIcon').then((mod) => mod.ClarityInnovationIcon)
);
const ClientExperienceIcon = dynamic(() =>
	import('@/icons/ClientExperienceIcon').then((mod) => mod.ClientExperienceIcon)
);
const ClientManIcon = dynamic(() => import('@/icons/ClientManIcon').then((mod) => mod.ClientManIcon));
const ClientOnboardingIcon = dynamic(() =>
	import('@/icons/ClientOnboardingIcon').then((mod) => mod.ClientOnboardingIcon)
);
const ClientWomanIcon = dynamic(() => import('@/icons/ClientWomanIcon').then((mod) => mod.ClientWomanIcon));
const CloseIconLarge = dynamic(() => import('@/icons/CloseIconLarge').then((mod) => mod.CloseIconLarge));
const CompanyIcon = dynamic(() => import('@/icons/CompanyIcon').then((mod) => mod.CompanyIcon));
const CompassIcon = dynamic(() => import('@/icons/CompassIcon').then((mod) => mod.CompassIcon));
const ContactIcon = dynamic(() => import('@/icons/ContactIcon').then((mod) => mod.ContactIcon));
const CopyIcon = dynamic(() => import('@/icons/CopyIcon').then((mod) => mod.CopyIcon));
const DialogIcon = dynamic(() => import('@/icons/DialogIcon').then((mod) => mod.DialogIcon));
const DialogSimpleIcon = dynamic(() => import('@/icons/DialogSimpleIcon').then((mod) => mod.DialogSimpleIcon));
const DisclamerIcon = dynamic(() => import('@/icons/DisclamerIcon').then((mod) => mod.DisclamerIcon));
const DocumentIcon = dynamic(() => import('@/icons/DocumentIcon').then((mod) => mod.DocumentIcon));
const DontTalkIcon = dynamic(() => import('@/icons/DontTalkIcon').then((mod) => mod.DontTalkIcon));
const EmailIcon = dynamic(() => import('@/icons/EmailIcon').then((mod) => mod.EmailIcon));
const EnvironmentalIcon = dynamic(() => import('@/icons/EnvironmentalIcon').then((mod) => mod.EnvironmentalIcon));
const EquitiesIcon = dynamic(() => import('@/icons/EquitiesIcon').then((mod) => mod.EquitiesIcon));
const ExchangeIcon = dynamic(() => import('@/icons/ExchangeIcon').then((mod) => mod.ExchangeIcon));
const ExternalLinkIcon = dynamic(() => import('@/icons/ExternalLinkIcon').then((mod) => mod.ExternalLinkIcon));
const FacebookIcon = dynamic(() => import('@/icons/FacebookIcon').then((mod) => mod.FacebookIcon));
const HelpIcon = dynamic(() => import('@/icons/HelpIcon').then((mod) => mod.HelpIcon));
const HomeIcon = dynamic(() => import('@/icons/HomeIcon').then((mod) => mod.HomeIcon));
const InstagramIcon = dynamic(() => import('@/icons/InstagramIcon').then((mod) => mod.InstagramIcon));
const LeafIcon = dynamic(() => import('@/icons/LeafIcon').then((mod) => mod.LeafIcon));
const LinkedinIcon = dynamic(() => import('@/icons/LinkedinIcon').then((mod) => mod.LinkedinIcon));
const LocationIcon = dynamic(() => import('@/icons/LocationIcon').then((mod) => mod.LocationIcon));
const LoginIcon = dynamic(() => import('@/icons/LoginIcon').then((mod) => mod.LoginIcon));
const MobileIcon = dynamic(() => import('@/icons/MobileIcon').then((mod) => mod.MobileIcon));
const MultipolarIcon = dynamic(() => import('@/icons/MultipolarIcon').then((mod) => mod.MultipolarIcon));
const OkIcon = dynamic(() => import('@/icons/OkIcon').then((mod) => mod.OkIcon));
const PenIcon = dynamic(() => import('@/icons/PenIcon').then((mod) => mod.PenIcon));
const PersonFemalIcon = dynamic(() => import('@/icons/PersonFemalIcon').then((mod) => mod.PersonFemalIcon));
const PrecautionIcon = dynamic(() => import('@/icons/PrecautionIcon').then((mod) => mod.PrecautionIcon));
const PuzzleIcon = dynamic(() => import('@/icons/PuzzleIcon').then((mod) => mod.PuzzleIcon));
const RmGuidanceIcon = dynamic(() => import('@/icons/RmGuidanceIcon').then((mod) => mod.RmGuidanceIcon));
const RocketIcon = dynamic(() => import('@/icons/RocketIcon').then((mod) => mod.RocketIcon));
const SavingIcon = dynamic(() => import('@/icons/SavingIcon').then((mod) => mod.SavingIcon));
const SelectionChoiceIcon = dynamic(() => import('@/icons/SelectionChoiceIcon').then((mod) => mod.SelectionChoiceIcon));
const SeveralPersonsIcon = dynamic(() => import('@/icons/SeveralPersonsIcon').then((mod) => mod.SeveralPersonsIcon));
const ShareIcon = dynamic(() => import('@/icons/ShareIcon').then((mod) => mod.ShareIcon));
const Status100Icon = dynamic(() => import('@/icons/Status100Icon').then((mod) => mod.Status100Icon));
const Status25Icon = dynamic(() => import('@/icons/Status25Icon').then((mod) => mod.Status25Icon));
const Status50Icon = dynamic(() => import('@/icons/Status50Icon').then((mod) => mod.Status50Icon));
const Status75Icon = dynamic(() => import('@/icons/Status75Icon').then((mod) => mod.Status75Icon));
const StrategyIcon = dynamic(() => import('@/icons/StrategyIcon').then((mod) => mod.StrategyIcon));
const SwissIcon = dynamic(() => import('@/icons/SwissIcon').then((mod) => mod.SwissIcon));
const ThumbDownIcon = dynamic(() => import('@/icons/ThumbDownIcon').then((mod) => mod.ThumbDownIcon));
const ThumbUpIcon = dynamic(() => import('@/icons/ThumbUpIcon').then((mod) => mod.ThumbUpIcon));
const TimeIcon = dynamic(() => import('@/icons/TimeIcon').then((mod) => mod.TimeIcon));
const TransactionIcon = dynamic(() => import('@/icons/TransactionIcon').then((mod) => mod.TransactionIcon));
const TrendsIcon = dynamic(() => import('@/icons/TrendsIcon').then((mod) => mod.TrendsIcon));
const TrashIcon = dynamic(() => import('@/icons/TrashIcon').then((mod) => mod.TrashIcon));
const ValueIcon = dynamic(() => import('@/icons/ValueIcon').then((mod) => mod.ValueIcon));
const YoutubeIcon = dynamic(() => import('@/icons/YoutubeIcon').then((mod) => mod.YoutubeIcon));
const ArrowRightIcon = dynamic(() => import('@/icons/ArrowRightIcon').then((mod) => mod.ArrowRightIcon));
const MenuIcon = dynamic(() => import('@/icons/MenuIcon').then((mod) => mod.MenuIcon));
const TwitterIcon = dynamic(() => import('@/icons/TwitterIcon').then((mod) => mod.TwitterIcon));
const MinusIcon = dynamic(() => import('@/icons/MinusIcon').then((mod) => mod.MinusIcon));
const PlayIcon = dynamic(() => import('@/icons/PlayIcon').then((mod) => mod.PlayIcon));
const PlusIcon = dynamic(() => import('@/icons/PlusIcon').then((mod) => mod.PlusIcon));
const SearchIcon = dynamic(() => import('@/icons/SearchIcon').then((mod) => mod.SearchIcon));
const StarIcon = dynamic(() => import('@/icons/StarIcon').then((mod) => mod.StarIcon));
const DownloadIcon = dynamic(() => import('@/icons/DownloadIcon').then((mod) => mod.DownloadIcon));
const DocumentPdfIcon = dynamic(() => import('@/icons/DocumentPdfIcon').then((mod) => mod.DocumentPdfIcon));
const DocumentAVIIcon = dynamic(() => import('@/icons/DocumentAVIIcon').then((mod) => mod.DocumentAVIIcon));
const DocumentDOCIcon = dynamic(() => import('@/icons/DocumentDOCIcon').then((mod) => mod.DocumentDOCIcon));
const DocumentPPTIcon = dynamic(() => import('@/icons/DocumentPPTIcon').then((mod) => mod.DocumentPPTIcon));
const DocumentTXTIcon = dynamic(() => import('@/icons/DocumentTXTIcon').then((mod) => mod.DocumentTXTIcon));
const DocumentXLSIcon = dynamic(() => import('@/icons/DocumentXLSIcon').then((mod) => mod.DocumentXLSIcon));
const DocumentZipIcon = dynamic(() => import('@/icons/DocumentZipIcon').then((mod) => mod.DocumentZipIcon));

const CacaoIcon = dynamic(() => import('@/icons/CacaoIcon').then((mod) => mod.CacaoIcon));
const VolumeUpIcon = dynamic(() => import('@/icons/VolumeUpIcon').then((mod) => mod.VolumeUpIcon));
const GlobalBrandsIcon = dynamic(() => import('@/icons/GlobalBrandsIcon').then((mod) => mod.GlobalBrandsIcon));
const RatingIcon = dynamic(() => import('@/icons/RatingIcon').then((mod) => mod.RatingIcon));
const TermsheetFrIcon = dynamic(() => import('@/icons/TermsheetFrIcon').then((mod) => mod.TermsheetFrIcon));
const ItIcon = dynamic(() => import('@/icons/ItIcon').then((mod) => mod.ItIcon));
const ProcessQuarterIcon = dynamic(() => import('@/icons/ProcessQuarterIcon').then((mod) => mod.ProcessQuarterIcon));
const WcMenIcon = dynamic(() => import('@/icons/WcMenIcon').then((mod) => mod.WcMenIcon));
const ArchiveIcon = dynamic(() => import('@/icons/ArchiveIcon').then((mod) => mod.ArchiveIcon));
const UpIcon = dynamic(() => import('@/icons/UpIcon').then((mod) => mod.UpIcon));
const FiveGIcon = dynamic(() => import('@/icons/FiveGIcon').then((mod) => mod.FiveGIcon));
const FilterIcon = dynamic(() => import('@/icons/FilterIcon').then((mod) => mod.FilterIcon));
const SupportIcon = dynamic(() => import('@/icons/SupportIcon').then((mod) => mod.SupportIcon));
const SmokeIcon = dynamic(() => import('@/icons/SmokeIcon').then((mod) => mod.SmokeIcon));
const QrCodeIcon = dynamic(() => import('@/icons/QrCodeIcon').then((mod) => mod.QrCodeIcon));
const UtilityIcon = dynamic(() => import('@/icons/UtilityIcon').then((mod) => mod.UtilityIcon));
const ShowerWomenIcon = dynamic(() => import('@/icons/ShowerWomenIcon').then((mod) => mod.ShowerWomenIcon));
const AgricultureIcon = dynamic(() => import('@/icons/AgricultureIcon').then((mod) => mod.AgricultureIcon));
const ProcessFullIcon = dynamic(() => import('@/icons/ProcessFullIcon').then((mod) => mod.ProcessFullIcon));
const ShowerMenIcon = dynamic(() => import('@/icons/ShowerMenIcon').then((mod) => mod.ShowerMenIcon));
const MoreIcon = dynamic(() => import('@/icons/MoreIcon').then((mod) => mod.MoreIcon));
const SnowIcon = dynamic(() => import('@/icons/SnowIcon').then((mod) => mod.SnowIcon));
const XingIcon = dynamic(() => import('@/icons/XingIcon').then((mod) => mod.XingIcon));
const KidItIcon = dynamic(() => import('@/icons/KidItIcon').then((mod) => mod.KidItIcon));
const GiftIcon = dynamic(() => import('@/icons/GiftIcon').then((mod) => mod.GiftIcon));
const VirusIcon = dynamic(() => import('@/icons/VirusIcon').then((mod) => mod.VirusIcon));
const SpeechBubbleIcon = dynamic(() => import('@/icons/SpeechBubbleIcon').then((mod) => mod.SpeechBubbleIcon));
const HouseholdProductsIcon = dynamic(() =>
	import('@/icons/HouseholdProductsIcon').then((mod) => mod.HouseholdProductsIcon)
);
const HardwareIcon = dynamic(() => import('@/icons/HardwareIcon').then((mod) => mod.HardwareIcon));
const WindmillIcon = dynamic(() => import('@/icons/WindmillIcon').then((mod) => mod.WindmillIcon));
const ProcessIcon = dynamic(() => import('@/icons/ProcessIcon').then((mod) => mod.ProcessIcon));
const EmergingMarketsIcon = dynamic(() => import('@/icons/EmergingMarketsIcon').then((mod) => mod.EmergingMarketsIcon));
const ForwardIcon = dynamic(() => import('@/icons/ForwardIcon').then((mod) => mod.ForwardIcon));
const KeyAccessIcon = dynamic(() => import('@/icons/KeyAccessIcon').then((mod) => mod.KeyAccessIcon));
const DentalIcon = dynamic(() => import('@/icons/DentalIcon').then((mod) => mod.DentalIcon));
const PetIcon = dynamic(() => import('@/icons/PetIcon').then((mod) => mod.PetIcon));
const LaptopIcon = dynamic(() => import('@/icons/LaptopIcon').then((mod) => mod.LaptopIcon));
const EnergyIcon = dynamic(() => import('@/icons/EnergyIcon').then((mod) => mod.EnergyIcon));
const SmartFarmingIcon = dynamic(() => import('@/icons/SmartFarmingIcon').then((mod) => mod.SmartFarmingIcon));
const AluIcon = dynamic(() => import('@/icons/AluIcon').then((mod) => mod.AluIcon));
const RestaurantIcon = dynamic(() => import('@/icons/RestaurantIcon').then((mod) => mod.RestaurantIcon));
const EScooterIcon = dynamic(() => import('@/icons/EScooterIcon').then((mod) => mod.EScooterIcon));
const MoonIcon = dynamic(() => import('@/icons/MoonIcon').then((mod) => mod.MoonIcon));
const ShopIcon = dynamic(() => import('@/icons/ShopIcon').then((mod) => mod.ShopIcon));
const SmileyIcon = dynamic(() => import('@/icons/SmileyIcon').then((mod) => mod.SmileyIcon));
const GlassIcon = dynamic(() => import('@/icons/GlassIcon').then((mod) => mod.GlassIcon));
const ConstructionIcon = dynamic(() => import('@/icons/ConstructionIcon').then((mod) => mod.ConstructionIcon));
const FuelIcon = dynamic(() => import('@/icons/FuelIcon').then((mod) => mod.FuelIcon));
const InflationLinkedBondsIcon = dynamic(() =>
	import('@/icons/InflationLinkedBondsIcon').then((mod) => mod.InflationLinkedBondsIcon)
);
const ToolsIcon = dynamic(() => import('@/icons/ToolsIcon').then((mod) => mod.ToolsIcon));
const DesinfectionIcon = dynamic(() => import('@/icons/DesinfectionIcon').then((mod) => mod.DesinfectionIcon));
const BatteryLowIcon = dynamic(() => import('@/icons/BatteryLowIcon').then((mod) => mod.BatteryLowIcon));
const WalletIcon = dynamic(() => import('@/icons/WalletIcon').then((mod) => mod.WalletIcon));
const WaterWaveIcon = dynamic(() => import('@/icons/WaterWaveIcon').then((mod) => mod.WaterWaveIcon));
const FullscreenIcon = dynamic(() => import('@/icons/FullscreenIcon').then((mod) => mod.FullscreenIcon));
const ResearchIcon = dynamic(() => import('@/icons/ResearchIcon').then((mod) => mod.ResearchIcon));
const TransportationIcon = dynamic(() => import('@/icons/TransportationIcon').then((mod) => mod.TransportationIcon));
const TermsheetDeIcon = dynamic(() => import('@/icons/TermsheetDeIcon').then((mod) => mod.TermsheetDeIcon));
const AwardsIcon = dynamic(() => import('@/icons/AwardsIcon').then((mod) => mod.AwardsIcon));
const RubbishIcon = dynamic(() => import('@/icons/RubbishIcon').then((mod) => mod.RubbishIcon));
const WeightIcon = dynamic(() => import('@/icons/WeightIcon').then((mod) => mod.WeightIcon));
const BellIcon = dynamic(() => import('@/icons/BellIcon').then((mod) => mod.BellIcon));
const CashSekIcon = dynamic(() => import('@/icons/CashSekIcon').then((mod) => mod.CashSekIcon));
const SoftwareIcon = dynamic(() => import('@/icons/SoftwareIcon').then((mod) => mod.SoftwareIcon));
const CloudIcon = dynamic(() => import('@/icons/CloudIcon').then((mod) => mod.CloudIcon));
const VrGlassesIcon = dynamic(() => import('@/icons/VrGlassesIcon').then((mod) => mod.VrGlassesIcon));
const ClearIcon = dynamic(() => import('@/icons/ClearIcon').then((mod) => mod.ClearIcon));
const TouchIdIcon = dynamic(() => import('@/icons/TouchIdIcon').then((mod) => mod.TouchIdIcon));
const ProcessZeroIcon = dynamic(() => import('@/icons/ProcessZeroIcon').then((mod) => mod.ProcessZeroIcon));
const NoEntryIcon = dynamic(() => import('@/icons/NoEntryIcon').then((mod) => mod.NoEntryIcon));
const TruckIcon = dynamic(() => import('@/icons/TruckIcon').then((mod) => mod.TruckIcon));
const InjectionIcon = dynamic(() => import('@/icons/InjectionIcon').then((mod) => mod.InjectionIcon));
const StopIcon = dynamic(() => import('@/icons/StopIcon').then((mod) => mod.StopIcon));
const InformationIcon = dynamic(() => import('@/icons/InformationIcon').then((mod) => mod.InformationIcon));
const BackwardIcon = dynamic(() => import('@/icons/BackwardIcon').then((mod) => mod.BackwardIcon));
const CoalIcon = dynamic(() => import('@/icons/CoalIcon').then((mod) => mod.CoalIcon));
const FoodProductsIcon = dynamic(() => import('@/icons/FoodProductsIcon').then((mod) => mod.FoodProductsIcon));
const SmallLargeCapsIcon = dynamic(() => import('@/icons/SmallLargeCapsIcon').then((mod) => mod.SmallLargeCapsIcon));
const ManagerIcon = dynamic(() => import('@/icons/ManagerIcon').then((mod) => mod.ManagerIcon));
const KidEnIcon = dynamic(() => import('@/icons/KidEnIcon').then((mod) => mod.KidEnIcon));
const SpotlightIcon = dynamic(() => import('@/icons/SpotlightIcon').then((mod) => mod.SpotlightIcon));
const PrinterRoomIcon = dynamic(() => import('@/icons/PrinterRoomIcon').then((mod) => mod.PrinterRoomIcon));
const ValveIcon = dynamic(() => import('@/icons/ValveIcon').then((mod) => mod.ValveIcon));
const InvestmentGradeBondsIcon = dynamic(() =>
	import('@/icons/InvestmentGradeBondsIcon').then((mod) => mod.InvestmentGradeBondsIcon)
);
const ExpandIcon = dynamic(() => import('@/icons/ExpandIcon').then((mod) => mod.ExpandIcon));
const SmartwatchIcon = dynamic(() => import('@/icons/SmartwatchIcon').then((mod) => mod.SmartwatchIcon));
const ObjectiveAdviceIcon = dynamic(() => import('@/icons/ObjectiveAdviceIcon').then((mod) => mod.ObjectiveAdviceIcon));
const DownIcon = dynamic(() => import('@/icons/DownIcon').then((mod) => mod.DownIcon));
const WindIcon = dynamic(() => import('@/icons/WindIcon').then((mod) => mod.WindIcon));
const PauseIcon = dynamic(() => import('@/icons/PauseIcon').then((mod) => mod.PauseIcon));
const CleaningToolsIcon = dynamic(() => import('@/icons/CleaningToolsIcon').then((mod) => mod.CleaningToolsIcon));
const MaterialMixedIcon = dynamic(() => import('@/icons/MaterialMixedIcon').then((mod) => mod.MaterialMixedIcon));
const PaperTowelsIcon = dynamic(() => import('@/icons/PaperTowelsIcon').then((mod) => mod.PaperTowelsIcon));
const SurveillanceIcon = dynamic(() => import('@/icons/SurveillanceIcon').then((mod) => mod.SurveillanceIcon));
const ToiletIcon = dynamic(() => import('@/icons/ToiletIcon').then((mod) => mod.ToiletIcon));
const CardsIcon = dynamic(() => import('@/icons/CardsIcon').then((mod) => mod.CardsIcon));
const BeveragesIcon = dynamic(() => import('@/icons/BeveragesIcon').then((mod) => mod.BeveragesIcon));
const BreadIcon = dynamic(() => import('@/icons/BreadIcon').then((mod) => mod.BreadIcon));
const AdditionalDocumentIcon = dynamic(() =>
	import('@/icons/AdditionalDocumentIcon').then((mod) => mod.AdditionalDocumentIcon)
);
const SimilarityIcon = dynamic(() => import('@/icons/SimilarityIcon').then((mod) => mod.SimilarityIcon));
const HotelsIcon = dynamic(() => import('@/icons/HotelsIcon').then((mod) => mod.HotelsIcon));
const FireIcon = dynamic(() => import('@/icons/FireIcon').then((mod) => mod.FireIcon));
const SecurityIcon = dynamic(() => import('@/icons/SecurityIcon').then((mod) => mod.SecurityIcon));
const RssFeedIcon = dynamic(() => import('@/icons/RssFeedIcon').then((mod) => mod.RssFeedIcon));
const SwissEquitiesIcon = dynamic(() => import('@/icons/SwissEquitiesIcon').then((mod) => mod.SwissEquitiesIcon));
const KidDeIcon = dynamic(() => import('@/icons/KidDeIcon').then((mod) => mod.KidDeIcon));
const AerospaceDefenceIcon = dynamic(() =>
	import('@/icons/AerospaceDefenceIcon').then((mod) => mod.AerospaceDefenceIcon)
);
const MetalsMiningIcon = dynamic(() => import('@/icons/MetalsMiningIcon').then((mod) => mod.MetalsMiningIcon));
const SendIcon = dynamic(() => import('@/icons/SendIcon').then((mod) => mod.SendIcon));
const HandicapIcon = dynamic(() => import('@/icons/HandicapIcon').then((mod) => mod.HandicapIcon));
const BondsIcon = dynamic(() => import('@/icons/BondsIcon').then((mod) => mod.BondsIcon));
const NoSmokeIcon = dynamic(() => import('@/icons/NoSmokeIcon').then((mod) => mod.NoSmokeIcon));
const BatteryFullIcon = dynamic(() => import('@/icons/BatteryFullIcon').then((mod) => mod.BatteryFullIcon));
const HierarchyIcon = dynamic(() => import('@/icons/HierarchyIcon').then((mod) => mod.HierarchyIcon));
const AlphaBasisIcon = dynamic(() => import('@/icons/AlphaBasisIcon').then((mod) => mod.AlphaBasisIcon));
const Status0Icon = dynamic(() => import('@/icons/Status0Icon').then((mod) => mod.Status0Icon));
const AudioIcon = dynamic(() => import('@/icons/AudioIcon').then((mod) => mod.AudioIcon));
const PersonIcon = dynamic(() => import('@/icons/PersonIcon').then((mod) => mod.PersonIcon));
const GreetingCardsIcon = dynamic(() => import('@/icons/GreetingCardsIcon').then((mod) => mod.GreetingCardsIcon));
const NotSecureIcon = dynamic(() => import('@/icons/NotSecureIcon').then((mod) => mod.NotSecureIcon));
const LivestreamInterruptedIcon = dynamic(() =>
	import('@/icons/LivestreamInterruptedIcon').then((mod) => mod.LivestreamInterruptedIcon)
);
const JobCvIcon = dynamic(() => import('@/icons/JobCvIcon').then((mod) => mod.JobCvIcon));
const BearIcon = dynamic(() => import('@/icons/BearIcon').then((mod) => mod.BearIcon));
const MedicineIcon = dynamic(() => import('@/icons/MedicineIcon').then((mod) => mod.MedicineIcon));
const DepotIcon = dynamic(() => import('@/icons/DepotIcon').then((mod) => mod.DepotIcon));
const ServerIcon = dynamic(() => import('@/icons/ServerIcon').then((mod) => mod.ServerIcon));
const UploadIcon = dynamic(() => import('@/icons/UploadIcon').then((mod) => mod.UploadIcon));
const ProcessHalfIcon = dynamic(() => import('@/icons/ProcessHalfIcon').then((mod) => mod.ProcessHalfIcon));
const DocumentAlertIcon = dynamic(() => import('@/icons/DocumentAlertIcon').then((mod) => mod.DocumentAlertIcon));
const BullIcon = dynamic(() => import('@/icons/BullIcon').then((mod) => mod.BullIcon));
const SunIcon = dynamic(() => import('@/icons/SunIcon').then((mod) => mod.SunIcon));
const EmailOpenIcon = dynamic(() => import('@/icons/EmailOpenIcon').then((mod) => mod.EmailOpenIcon));
const ProcessThreeQuarterIcon = dynamic(() =>
	import('@/icons/ProcessThreeQuarterIcon').then((mod) => mod.ProcessThreeQuarterIcon)
);
const BookMagazineIcon = dynamic(() => import('@/icons/BookMagazineIcon').then((mod) => mod.BookMagazineIcon));
const VentilationIcon = dynamic(() => import('@/icons/VentilationIcon').then((mod) => mod.VentilationIcon));
const ScrewIcon = dynamic(() => import('@/icons/ScrewIcon').then((mod) => mod.ScrewIcon));
const BusinessInGeneralIcon = dynamic(() =>
	import('@/icons/BusinessInGeneralIcon').then((mod) => mod.BusinessInGeneralIcon)
);
const LivestreamIcon = dynamic(() => import('@/icons/LivestreamIcon').then((mod) => mod.LivestreamIcon));
const WaterIcon = dynamic(() => import('@/icons/WaterIcon').then((mod) => mod.WaterIcon));
const TermsheetEnIcon = dynamic(() => import('@/icons/TermsheetEnIcon').then((mod) => mod.TermsheetEnIcon));
const TelecommunicationIcon = dynamic(() =>
	import('@/icons/TelecommunicationIcon').then((mod) => mod.TelecommunicationIcon)
);
const TreeIcon = dynamic(() => import('@/icons/TreeIcon').then((mod) => mod.TreeIcon));
const MovieIcon = dynamic(() => import('@/icons/MovieIcon').then((mod) => mod.MovieIcon));
const ElectricalEquipmentIcon = dynamic(() =>
	import('@/icons/ElectricalEquipmentIcon').then((mod) => mod.ElectricalEquipmentIcon)
);
const PortfolioIcon = dynamic(() => import('@/icons/PortfolioIcon').then((mod) => mod.PortfolioIcon));
const SoapIcon = dynamic(() => import('@/icons/SoapIcon').then((mod) => mod.SoapIcon));
const ObserveIcon = dynamic(() => import('@/icons/ObserveIcon').then((mod) => mod.ObserveIcon));
const HighYieldBondsIcon = dynamic(() => import('@/icons/HighYieldBondsIcon').then((mod) => mod.HighYieldBondsIcon));
const WatchIcon = dynamic(() => import('@/icons/WatchIcon').then((mod) => mod.WatchIcon));
const ConveyerBeltIcon = dynamic(() => import('@/icons/ConveyerBeltIcon').then((mod) => mod.ConveyerBeltIcon));
const TabletIcon = dynamic(() => import('@/icons/TabletIcon').then((mod) => mod.TabletIcon));
const EuropeanIcon = dynamic(() => import('@/icons/EuropeanIcon').then((mod) => mod.EuropeanIcon));
const VimeoIcon = dynamic(() => import('@/icons/VimeoIcon').then((mod) => mod.VimeoIcon));
const VolumeDownIcon = dynamic(() => import('@/icons/VolumeDownIcon').then((mod) => mod.VolumeDownIcon));
const OilPipeIcon = dynamic(() => import('@/icons/OilPipeIcon').then((mod) => mod.OilPipeIcon));
const RecycleIcon = dynamic(() => import('@/icons/RecycleIcon').then((mod) => mod.RecycleIcon));
const RoadRailIcon = dynamic(() => import('@/icons/RoadRailIcon').then((mod) => mod.RoadRailIcon));
const SettingsIcon = dynamic(() => import('@/icons/SettingsIcon').then((mod) => mod.SettingsIcon));
const ESportsIcon = dynamic(() => import('@/icons/ESportsIcon').then((mod) => mod.ESportsIcon));
const DishesIcon = dynamic(() => import('@/icons/DishesIcon').then((mod) => mod.DishesIcon));
const ElevatorIcon = dynamic(() => import('@/icons/ElevatorIcon').then((mod) => mod.ElevatorIcon));
const PillsIcon = dynamic(() => import('@/icons/PillsIcon').then((mod) => mod.PillsIcon));
const ComputerIcon = dynamic(() => import('@/icons/ComputerIcon').then((mod) => mod.ComputerIcon));
const KidFrIcon = dynamic(() => import('@/icons/KidFrIcon').then((mod) => mod.KidFrIcon));
const PersonalizeIcon = dynamic(() => import('@/icons/PersonalizeIcon').then((mod) => mod.PersonalizeIcon));
const HeatingIcon = dynamic(() => import('@/icons/HeatingIcon').then((mod) => mod.HeatingIcon));
const VacationIcon = dynamic(() => import('@/icons/VacationIcon').then((mod) => mod.VacationIcon));
const FamilyIcon = dynamic(() => import('@/icons/FamilyIcon').then((mod) => mod.FamilyIcon));
const MoneyIcon = dynamic(() => import('@/icons/MoneyIcon').then((mod) => mod.MoneyIcon));
const LikeIcon = dynamic(() => import('@/icons/LikeIcon').then((mod) => mod.LikeIcon));
const AudioMuteIcon = dynamic(() => import('@/icons/AudioMuteIcon').then((mod) => mod.AudioMuteIcon));
const CameraIcon = dynamic(() => import('@/icons/CameraIcon').then((mod) => mod.CameraIcon));
const NewsIcon = dynamic(() => import('@/icons/NewsIcon').then((mod) => mod.NewsIcon));
const ContractIcon = dynamic(() => import('@/icons/ContractIcon').then((mod) => mod.ContractIcon));
const AutomobilesIcon = dynamic(() => import('@/icons/AutomobilesIcon').then((mod) => mod.AutomobilesIcon));
const ThreeDPrinterIcon = dynamic(() => import('@/icons/ThreeDPrinterIcon').then((mod) => mod.ThreeDPrinterIcon));
const NespressoIcon = dynamic(() => import('@/icons/NespressoIcon').then((mod) => mod.NespressoIcon));
const PrintIcon = dynamic(() => import('@/icons/PrintIcon').then((mod) => mod.PrintIcon));
const PodcastIcon = dynamic(() => import('@/icons/PodcastIcon').then((mod) => mod.PodcastIcon));
const HealthCareIcon = dynamic(() => import('@/icons/HealthCareIcon').then((mod) => mod.HealthCareIcon));
const RoboticIcon = dynamic(() => import('@/icons/RoboticIcon').then((mod) => mod.RoboticIcon));
const IndustrialsIcon = dynamic(() => import('@/icons/IndustrialsIcon').then((mod) => mod.IndustrialsIcon));
const ConsumerStaplesIcon = dynamic(() => import('@/icons/ConsumerStaplesIcon').then((mod) => mod.ConsumerStaplesIcon));
const CoffeeIcon = dynamic(() => import('@/icons/CoffeeIcon').then((mod) => mod.CoffeeIcon));
const PercentIcon = dynamic(() => import('@/icons/PercentIcon').then((mod) => mod.PercentIcon));
const TermsheetItIcon = dynamic(() => import('@/icons/TermsheetItIcon').then((mod) => mod.TermsheetItIcon));
const GondolaIcon = dynamic(() => import('@/icons/GondolaIcon').then((mod) => mod.GondolaIcon));
const CommoditiesIcon = dynamic(() => import('@/icons/CommoditiesIcon').then((mod) => mod.CommoditiesIcon));
const MoreDotsIcon = dynamic(() => import('@/icons/MoreDotsIcon').then((mod) => mod.MoreDotsIcon));
const GoldIcon = dynamic(() => import('@/icons/GoldIcon').then((mod) => mod.GoldIcon));
const LogoutIcon = dynamic(() => import('@/icons/LogoutIcon').then((mod) => mod.LogoutIcon));
const OilIcon = dynamic(() => import('@/icons/OilIcon').then((mod) => mod.OilIcon));
const HearingIcon = dynamic(() => import('@/icons/HearingIcon').then((mod) => mod.HearingIcon));
const ProfessionalServicesIcon = dynamic(() =>
	import('@/icons/ProfessionalServicesIcon').then((mod) => mod.ProfessionalServicesIcon)
);
const BiotechnologyIcon = dynamic(() => import('@/icons/BiotechnologyIcon').then((mod) => mod.BiotechnologyIcon));
const BatteryMediumIcon = dynamic(() => import('@/icons/BatteryMediumIcon').then((mod) => mod.BatteryMediumIcon));
const CertificateIcon = dynamic(() => import('@/icons/CertificateIcon').then((mod) => mod.CertificateIcon));
const ClimateChangeIcon = dynamic(() => import('@/icons/ClimateChangeIcon').then((mod) => mod.ClimateChangeIcon));
const BagIcon = dynamic(() => import('@/icons/BagIcon').then((mod) => mod.BagIcon));
const ConsumerDiscretionaryIcon = dynamic(() =>
	import('@/icons/ConsumerDiscretionaryIcon').then((mod) => mod.ConsumerDiscretionaryIcon)
);
const RecyclingIcon = dynamic(() => import('@/icons/RecyclingIcon').then((mod) => mod.RecyclingIcon));
const SmileySadIcon = dynamic(() => import('@/icons/SmileySadIcon').then((mod) => mod.SmileySadIcon));
const WardrobeIcon = dynamic(() => import('@/icons/WardrobeIcon').then((mod) => mod.WardrobeIcon));
const AirlinesIcon = dynamic(() => import('@/icons/AirlinesIcon').then((mod) => mod.AirlinesIcon));
const PlantIcon = dynamic(() => import('@/icons/PlantIcon').then((mod) => mod.PlantIcon));
const CurrencyIcon = dynamic(() => import('@/icons/CurrencyIcon').then((mod) => mod.CurrencyIcon));
const TransparencyIcon = dynamic(() => import('@/icons/TransparencyIcon').then((mod) => mod.TransparencyIcon));
const MotorcycleVespaIcon = dynamic(() => import('@/icons/MotorcycleVespaIcon').then((mod) => mod.MotorcycleVespaIcon));
const TechnologyIcon = dynamic(() => import('@/icons/TechnologyIcon').then((mod) => mod.TechnologyIcon));
const MarineIcon = dynamic(() => import('@/icons/MarineIcon').then((mod) => mod.MarineIcon));
const InfinityIcon = dynamic(() => import('@/icons/InfinityIcon').then((mod) => mod.InfinityIcon));
const BalanceIcon = dynamic(() => import('@/icons/BalanceIcon').then((mod) => mod.BalanceIcon));
const ConnectionIcon = dynamic(() => import('@/icons/ConnectionIcon').then((mod) => mod.ConnectionIcon));
const SmileySpeechlessIcon = dynamic(() =>
	import('@/icons/SmileySpeechlessIcon').then((mod) => mod.SmileySpeechlessIcon)
);

export const getIcon = (icon: Icon) => {
	switch (icon) {
		case Icon.ALERT:
			return <AlertIcon />;
		case Icon.ALPHA_PYRAMIDE:
			return <AlphaPyramideIcon />;
		case Icon.ARROW_RIGHT:
			return <ArrowRightIcon />;
		case Icon.ASSET_ALLOCATION:
			return <AssetAllocationIcon />;
		case Icon.BOOKMARK:
			return <BookmarkIcon />;
		case Icon.CALCULATOR:
			return <CalculatorIcon />;
		case Icon.CALENDAR:
			return <CalendarIcon />;
		case Icon.CLARITY_INNOVATION:
			return <ClarityInnovationIcon />;
		case Icon.CLIENT_EXPERIENCE:
			return <ClientExperienceIcon />;
		case Icon.CLIENT_MAN:
			return <ClientManIcon />;
		case Icon.CLIENT_ONBOARDING:
			return <ClientOnboardingIcon />;
		case Icon.CLIENT_WOMAN:
			return <ClientWomanIcon />;
		case Icon.CLOSE:
			return <CloseIconLarge />;
		case Icon.COMPANY:
			return <CompanyIcon />;
		case Icon.COMPASS:
			return <CompassIcon />;
		case Icon.CONTACT:
			return <ContactIcon />;
		case Icon.COPY:
			return <CopyIcon />;
		case Icon.DIALOG:
			return <DialogIcon />;
		case Icon.DIALOG_SIMPLE:
			return <DialogSimpleIcon />;
		case Icon.DISCLAIMER:
			return <DisclamerIcon />;
		case Icon.DOCUMENT:
			return <DocumentIcon />;
		case Icon.DONT_TALK:
			return <DontTalkIcon />;
		case Icon.EMAIL:
			return <EmailIcon />;
		case Icon.ENVIRONMENTAL:
			return <EnvironmentalIcon />;
		case Icon.EQUITIES:
			return <EquitiesIcon />;
		case Icon.EXCHANGE:
			return <ExchangeIcon />;
		case Icon.EXTERNAL_LINK:
			return <ExternalLinkIcon />;
		case Icon.FACEBOOK:
			return <FacebookIcon />;
		case Icon.HELP:
			return <HelpIcon />;
		case Icon.HOME:
			return <HomeIcon />;
		case Icon.INSTAGRAM:
			return <InstagramIcon />;
		case Icon.LEAF:
			return <LeafIcon />;
		case Icon.LINKEDIN:
			return <LinkedinIcon />;
		case Icon.LOCATION:
			return <LocationIcon />;
		case Icon.LOGIN:
			return <LoginIcon />;
		case Icon.MENU:
			return <MenuIcon />;
		case Icon.MINUS:
			return <MinusIcon />;
		case Icon.MOBILE:
			return <MobileIcon />;
		case Icon.MULTIPOLAR:
			return <MultipolarIcon />;
		case Icon.OK:
			return <OkIcon />;
		case Icon.PEN:
			return <PenIcon />;
		case Icon.PERSON_FEMAL:
			return <PersonFemalIcon />;
		case Icon.PLAY:
			return <PlayIcon />;
		case Icon.PLUS:
			return <PlusIcon />;
		case Icon.PRECAUTION:
			return <PrecautionIcon />;
		case Icon.PUZZLE:
			return <PuzzleIcon />;
		case Icon.RM_GUIDEANCE:
			return <RmGuidanceIcon />;
		case Icon.ROCKET:
			return <RocketIcon />;
		case Icon.SAVING:
			return <SavingIcon />;
		case Icon.SEARCH:
			return <SearchIcon />;
		case Icon.SELECTION_CHOICE:
			return <SelectionChoiceIcon />;
		case Icon.SEVERAL_PERSONS:
			return <SeveralPersonsIcon />;
		case Icon.SHARE:
			return <ShareIcon />;
		case Icon.STATUS_25:
			return <Status25Icon />;
		case Icon.STATUS_50:
			return <Status50Icon />;
		case Icon.STATUS_75:
			return <Status75Icon />;
		case Icon.STATUS_100:
			return <Status100Icon />;
		case Icon.STAR:
			return <StarIcon />;
		case Icon.STRATEGY:
			return <StrategyIcon />;
		case Icon.SWISS:
			return <SwissIcon />;
		case Icon.THUMB_DOWN:
			return <ThumbDownIcon />;
		case Icon.THUMB_UP:
			return <ThumbUpIcon />;
		case Icon.TIME:
			return <TimeIcon />;
		case Icon.TRANSACTION:
			return <TransactionIcon />;
		case Icon.TRASH:
			return <TrashIcon />;
		case Icon.TRENDS:
			return <TrendsIcon />;
		case Icon.TWITTER:
			return <TwitterIcon />;
		case Icon.VALUE:
			return <ValueIcon />;
		case Icon.YOUTUBE:
			return <YoutubeIcon />;
		case Icon.DOWNLOAD:
			return <DownloadIcon />;
		case Icon.DOCUMENT_PDF:
			return <DocumentPdfIcon />;
		case Icon.DOCUMENT_AVI:
			return <DocumentAVIIcon />;
		case Icon.DOCUMENT_DOC:
			return <DocumentDOCIcon />;
		case Icon.DOCUMENT_PPT:
			return <DocumentPPTIcon />;
		case Icon.DOCUMENT_TXT:
			return <DocumentTXTIcon />;
		case Icon.DOCUMENT_XLS:
			return <DocumentXLSIcon />;
		case Icon.DOCUMENT_ZIP:
			return <DocumentZipIcon />;
		case Icon.CACAO:
			return <CacaoIcon />;
		case Icon.VOLUME_UP:
			return <VolumeUpIcon />;
		case Icon.GLOBAL_BRANDS:
			return <GlobalBrandsIcon />;
		case Icon.RATING:
			return <RatingIcon />;
		case Icon.TERMSHEET_FR:
			return <TermsheetFrIcon />;
		case Icon.IT:
			return <ItIcon />;
		case Icon.PROCESS_QUARTER:
			return <ProcessQuarterIcon />;
		case Icon.WC_MEN:
			return <WcMenIcon />;
		case Icon.ARCHIVE:
			return <ArchiveIcon />;
		case Icon.UP:
			return <UpIcon />;
		case Icon._5G:
			return <FiveGIcon />;
		case Icon.FILTER:
			return <FilterIcon />;
		case Icon.SUPPORT:
			return <SupportIcon />;
		case Icon.SMOKE:
			return <SmokeIcon />;
		case Icon.QRCODE:
			return <QrCodeIcon />;
		case Icon.UTILITY:
			return <UtilityIcon />;
		case Icon.SHOWER_WOMEN:
			return <ShowerWomenIcon />;
		case Icon.AGRICULTURE:
			return <AgricultureIcon />;
		case Icon.PROCESS_FULL:
			return <ProcessFullIcon />;
		case Icon.SHOWER_MEN:
			return <ShowerMenIcon />;
		case Icon.VONTOBEL_WIFI_RGB_CITRINE:
			return <WiFiIcon />;
		case Icon.MORE:
			return <MoreIcon />;
		case Icon.SNOW:
			return <SnowIcon />;
		case Icon.XING:
			return <XingIcon />;
		case Icon.KID_IT:
			return <KidItIcon />;
		case Icon.GIFT:
			return <GiftIcon />;
		case Icon.VIRUS:
			return <VirusIcon />;
		case Icon.SPEECH_BUBBLE:
			return <SpeechBubbleIcon />;
		case Icon.HOUSEHOLD_PRODUCTS:
			return <HouseholdProductsIcon />;
		case Icon.HARDWARE:
			return <HardwareIcon />;
		case Icon.WINDMILL:
			return <WindmillIcon />;
		case Icon.PROCESS:
			return <ProcessIcon />;
		case Icon.EMERGING_MARKETS:
			return <EmergingMarketsIcon />;
		case Icon.FORWARD:
			return <ForwardIcon />;
		case Icon.KEYACCESS:
			return <KeyAccessIcon />;
		case Icon.DENTAL:
			return <DentalIcon />;
		case Icon.PET:
			return <PetIcon />;
		case Icon.LAPTOP:
			return <LaptopIcon />;
		case Icon.ENERGY:
			return <EnergyIcon />;
		case Icon.SMART_FARMING:
			return <SmartFarmingIcon />;
		case Icon.EXPAND:
			return <ExpandIcon />;
		case Icon.ALU:
			return <AluIcon />;
		case Icon.RESTAURANT:
			return <RestaurantIcon />;
		case Icon.ESCOOTER:
			return <EScooterIcon />;
		case Icon.MOON:
			return <MoonIcon />;
		case Icon.SHOP:
			return <ShopIcon />;
		case Icon.SMILEY:
			return <SmileyIcon />;
		case Icon.GLASS:
			return <GlassIcon />;
		case Icon.CONSTRUCTION:
			return <ConstructionIcon />;
		case Icon.FUEL:
			return <FuelIcon />;
		case Icon.INFLATION_LINKED_BONDS:
			return <InflationLinkedBondsIcon />;
		case Icon.TOOLS:
			return <ToolsIcon />;
		case Icon.DESINFECTION:
			return <DesinfectionIcon />;
		case Icon.BATTERY_LOW:
			return <BatteryLowIcon />;
		case Icon.WALLET:
			return <WalletIcon />;
		case Icon.CONNECTION:
			return <ConnectionIcon />;
		case Icon.WATER_WAVE:
			return <WaterWaveIcon />;
		case Icon.FULLSCREEN:
			return <FullscreenIcon />;
		case Icon.RESEARCH:
			return <ResearchIcon />;
		case Icon.TRANSPORTATION:
			return <TransportationIcon />;
		case Icon.UPLOAD:
			return <UploadIcon />;
		case Icon.TERMSHEET_DE:
			return <TermsheetDeIcon />;
		case Icon.AWARDS:
			return <AwardsIcon />;
		case Icon.RUBBISH:
			return <RubbishIcon />;
		case Icon.WEIGHT:
			return <WeightIcon />;
		case Icon.BELL:
			return <BellIcon />;
		case Icon.CASH_SEK:
			return <CashSekIcon />;
		case Icon.SOFTWARE:
			return <SoftwareIcon />;
		case Icon.CLOUD:
			return <CloudIcon />;
		case Icon.VR_GLASSES:
			return <VrGlassesIcon />;
		case Icon.CLEAR:
			return <ClearIcon />;
		case Icon.TOUCH_ID:
			return <TouchIdIcon />;
		case Icon.PROCESS_ZERO:
			return <ProcessZeroIcon />;
		case Icon.NO_ENTRY:
			return <NoEntryIcon />;
		case Icon.TRUCK:
			return <TruckIcon />;
		case Icon.INJECTION:
			return <InjectionIcon />;
		case Icon.STOP:
			return <StopIcon />;
		case Icon.INFORMATION:
			return <InformationIcon />;
		case Icon.BACKWARD:
			return <BackwardIcon />;
		case Icon.COAL:
			return <CoalIcon />;
		case Icon.FOOD_PRODUCTS:
			return <FoodProductsIcon />;
		case Icon.SMALL_LARGE_CAPS:
			return <SmallLargeCapsIcon />;
		case Icon.MANAGER:
			return <ManagerIcon />;
		case Icon.KID_EN:
			return <KidEnIcon />;
		case Icon.SPOTLIGHT:
			return <SpotlightIcon />;
		case Icon.PRINTER_ROOM:
			return <PrinterRoomIcon />;
		case Icon.VALVE:
			return <ValveIcon />;
		case Icon.VONTOBEL_INVESTMENT_GRADE_BONDS_RGB_SAND:
			return <InvestmentGradeBondsIcon />;
		case Icon.SMARTWATCH:
			return <SmartwatchIcon />;
		case Icon.OBJECTIVE_ADVICE:
			return <ObjectiveAdviceIcon />;
		case Icon.DOWN:
			return <DownIcon />;
		case Icon.WIND:
			return <WindIcon />;
		case Icon.PAUSE:
			return <PauseIcon />;
		case Icon.CLEANING_TOOLS:
			return <CleaningToolsIcon />;
		case Icon.MATERIAL_MIXED:
			return <MaterialMixedIcon />;
		case Icon.PAPER_TOWELS:
			return <PaperTowelsIcon />;
		case Icon.SURVEILLANCE:
			return <SurveillanceIcon />;
		case Icon.TOILET:
			return <ToiletIcon />;
		case Icon.CARDS:
			return <CardsIcon />;
		case Icon.BEVERAGES:
			return <BeveragesIcon />;
		case Icon.BREAD:
			return <BreadIcon />;
		case Icon.ADDITIONAL_DOCUMENT:
			return <AdditionalDocumentIcon />;
		case Icon.SIMILARITY:
			return <SimilarityIcon />;
		case Icon.HOTELS:
			return <HotelsIcon />;
		case Icon.FIRE:
			return <FireIcon />;
		case Icon.SECURITY:
			return <SecurityIcon />;
		case Icon.RSS_FEED:
			return <RssFeedIcon />;
		case Icon.SWISS_EQUITIES:
			return <SwissEquitiesIcon />;
		case Icon.KID_DE:
			return <KidDeIcon />;
		case Icon.AEROSPACE_DEFENCE:
			return <AerospaceDefenceIcon />;
		case Icon.METALS_MINING:
			return <MetalsMiningIcon />;
		case Icon.SEND:
			return <SendIcon />;
		case Icon.HANDICAP:
			return <HandicapIcon />;
		case Icon.BONDS:
			return <BondsIcon />;
		case Icon.NO_SMOKE:
			return <NoSmokeIcon />;
		case Icon.BATTERY_FULL:
			return <BatteryFullIcon />;
		case Icon.HIERARCHY:
			return <HierarchyIcon />;
		case Icon.ALPHA_BASIS:
			return <AlphaBasisIcon />;
		case Icon.STATUS_0:
			return <Status0Icon />;
		case Icon.AUDIO:
			return <AudioIcon />;
		case Icon.PERSON:
			return <PersonIcon />;
		case Icon.GREETING_CARDS:
			return <GreetingCardsIcon />;
		case Icon.NOT_SECURE:
			return <NotSecureIcon />;
		case Icon.LIVESTREAM_INTERRUPTED:
			return <LivestreamInterruptedIcon />;
		case Icon.JOB_CV:
			return <JobCvIcon />;
		case Icon.BEAR:
			return <BearIcon />;
		case Icon.MEDICINE:
			return <MedicineIcon />;
		case Icon.DEPOT:
			return <DepotIcon />;
		case Icon.SERVER:
			return <ServerIcon />;
		case Icon.PROCESS_HALF:
			return <ProcessHalfIcon />;
		case Icon.DOCUMENT_ALERT:
			return <DocumentAlertIcon />;
		case Icon.BULL:
			return <BullIcon />;
		case Icon.SUN:
			return <SunIcon />;
		case Icon.EMAIL_OPEN:
			return <EmailOpenIcon />;
		case Icon.PROCESS_THREEQUARTER:
			return <ProcessThreeQuarterIcon />;
		case Icon.BOOK_MAGAZINE:
			return <BookMagazineIcon />;
		case Icon.VENTILATION:
			return <VentilationIcon />;
		case Icon.SCREW:
			return <ScrewIcon />;
		case Icon.BUSINESS_IN_GENERAL:
			return <BusinessInGeneralIcon />;
		case Icon.LIVESTREAM:
			return <LivestreamIcon />;
		case Icon.WATER:
			return <WaterIcon />;
		case Icon.TERMSHEET_EN:
			return <TermsheetEnIcon />;
		case Icon.TELECOMMUNICATION:
			return <TelecommunicationIcon />;
		case Icon.TREE:
			return <TreeIcon />;
		case Icon.MOVIE:
			return <MovieIcon />;
		case Icon.ELECTRICAL_EQUIPMENT:
			return <ElectricalEquipmentIcon />;
		case Icon.PORTFOLIO:
			return <PortfolioIcon />;
		case Icon.SOAP:
			return <SoapIcon />;
		case Icon.OBSERVE:
			return <ObserveIcon />;
		case Icon.HIGH_YIELD_BONDS:
			return <HighYieldBondsIcon />;
		case Icon.WATCH:
			return <WatchIcon />;
		case Icon.CONVEYER_BELT:
			return <ConveyerBeltIcon />;
		case Icon.TABLET:
			return <TabletIcon />;
		case Icon.EUROPEAN:
			return <EuropeanIcon />;
		case Icon.VIMEO:
			return <VimeoIcon />;
		case Icon.VOLUME_DOWN:
			return <VolumeDownIcon />;
		case Icon.OIL_PIPE:
			return <OilPipeIcon />;
		case Icon.RECYCLE:
			return <RecycleIcon />;
		case Icon.ROAD_RAIL:
			return <RoadRailIcon />;
		case Icon.SETTINGS:
			return <SettingsIcon />;
		case Icon.ESPORTS:
			return <ESportsIcon />;
		case Icon.DISHES:
			return <DishesIcon />;
		case Icon.ELEVATOR:
			return <ElevatorIcon />;
		case Icon.PILLS:
			return <PillsIcon />;
		case Icon.COMPUTER:
			return <ComputerIcon />;
		case Icon.KID_FR:
			return <KidFrIcon />;
		case Icon.PERSONALIZE:
			return <PersonalizeIcon />;
		case Icon.HEATING:
			return <HeatingIcon />;
		case Icon.VACATION:
			return <VacationIcon />;
		case Icon.FAMILY:
			return <FamilyIcon />;
		case Icon.MONEY:
			return <MoneyIcon />;
		case Icon.LIKE:
			return <LikeIcon />;
		case Icon.AUDIO_MUTE:
			return <AudioMuteIcon />;
		case Icon.CAMERA:
			return <CameraIcon />;
		case Icon.NEWS:
			return <NewsIcon />;
		case Icon.CONTRACT:
			return <ContractIcon />;
		case Icon.AUTOMOBILES:
			return <AutomobilesIcon />;
		case Icon._3D_PRINTER:
			return <ThreeDPrinterIcon />;
		case Icon.NESPRESSO:
			return <NespressoIcon />;
		case Icon.PRINT:
			return <PrintIcon />;
		case Icon.PODCAST:
			return <PodcastIcon />;
		case Icon.HEALTH_CARE:
			return <HealthCareIcon />;
		case Icon.ROBOTIC:
			return <RoboticIcon />;
		case Icon.INDUSTRIALS:
			return <IndustrialsIcon />;
		case Icon.CONSUMER_STAPLES:
			return <ConsumerStaplesIcon />;
		case Icon.COFFEE:
			return <CoffeeIcon />;
		case Icon.PERCENT:
			return <PercentIcon />;
		case Icon.TERMSHEET_IT:
			return <TermsheetItIcon />;
		case Icon.GONDOLA:
			return <GondolaIcon />;
		case Icon.COMMODITIES:
			return <CommoditiesIcon />;
		case Icon.MORE_DOTS:
			return <MoreDotsIcon />;
		case Icon.GOLD:
			return <GoldIcon />;
		case Icon.LOGOUT:
			return <LogoutIcon />;
		case Icon.OIL:
			return <OilIcon />;
		case Icon.HEARING:
			return <HearingIcon />;
		case Icon.PROFESSIONAL_SERVICES:
			return <ProfessionalServicesIcon />;
		case Icon.BIOTECHNOLOGY:
			return <BiotechnologyIcon />;
		case Icon.BATTERY_MEDIUM:
			return <BatteryMediumIcon />;
		case Icon.CERTIFICATE:
			return <CertificateIcon />;
		case Icon.CLIMATE_CHANGE:
			return <ClimateChangeIcon />;
		case Icon.BAG:
			return <BagIcon />;
		case Icon.CONSUMER_DISCRETIONARY:
			return <ConsumerDiscretionaryIcon />;
		case Icon.RECYCLING:
			return <RecyclingIcon />;
		case Icon.SMILEY_SAD:
			return <SmileySadIcon />;
		case Icon.WARDROBE:
			return <WardrobeIcon />;
		case Icon.AIRLINES:
			return <AirlinesIcon />;
		case Icon.PLANT:
			return <PlantIcon />;
		case Icon.CURRENCY:
			return <CurrencyIcon />;
		case Icon.TRANSPARENCY:
			return <TransparencyIcon />;
		case Icon.MOTORCYCLE_VESPA:
			return <MotorcycleVespaIcon />;
		case Icon.TECHNOLOGY:
			return <TechnologyIcon />;
		case Icon.MARINE:
			return <MarineIcon />;
		case Icon.INFINITY:
			return <InfinityIcon />;
		case Icon.BALANCE:
			return <BalanceIcon />;
		case Icon.SMILEY_SPEECHLESS:
			return <SmileySpeechlessIcon />;

		default:
			return undefined;
	}
};

export const getIconByMimeType = (mimeType: MimeType) => {
	switch (mimeType) {
		case MimeType.PDF:
			return <DocumentPdfIcon />;
		case MimeType.DOC:
			return <DocumentDOCIcon />;
		case MimeType.DOCX:
			return <DocumentDOCIcon />;
		case MimeType.XLS:
			return <DocumentXLSIcon />;
		case MimeType.XLSX:
			return <DocumentXLSIcon />;
		case MimeType.PPT:
			return <DocumentPPTIcon />;
		case MimeType.PPTX:
			return <DocumentPPTIcon />;
		case MimeType.AVI:
			return <DocumentAVIIcon />;
		case MimeType.TXT:
			return <DocumentTXTIcon />;
		case MimeType.ZIP:
			return <DocumentZipIcon />;

		default:
			return <DocumentIcon />;
	}
};
